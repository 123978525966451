import { signedRequest } from "./api";

export const getChallenges = async () => {
  const response = await signedRequest({
    method: "get",
    path: "/api/gambit/challenges",
  });
  return response.data;
};

export const myChallenges = async () => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/my-challenges`,
  });
  return response.data;
};

export const getChallengeDetails = async ({ challengeId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/challenges/${challengeId}`,
  });

  return response.data;
};
