import {
  ScrollingContainer,
  ColumnScrolling,
  PaddingComponent,
} from "components/components";
import AppBar from "components/appbar";

const AirdropPage = () => {
  return (
    <ScrollingContainer>
      <AppBar title="Airdrop" />
      <ColumnScrolling>
        <PaddingComponent height="20px" />
      </ColumnScrolling>
    </ScrollingContainer>
  );
};

export default AirdropPage;
