import { createGlobalStyle } from "styled-components";

const fontFamily = "Roboto, sans-serif";

export const TextTheme = createGlobalStyle`
  .titleLarge { 
    font-size: 24px; 
    font-weight: 500; 
    letter-spacing: 0; 
    line-height: ${34 / 24}; 
    font-family: ${fontFamily}; 
  }
  .titleMedium { 
    font-size: 20px; 
    font-weight: 500; 
    line-height: ${24 / 20}; 
    font-family: ${fontFamily}; 
  }
  .titleSmall { 
    font-size: 18px; 
    font-weight: 500; 
    letter-spacing: 0; 
    line-height: ${22 / 18}; 
    font-family: ${fontFamily}; 
  }
  .bodyLarge { 
    font-size: 15px; 
    font-weight: 600; 
    letter-spacing: 0; 
    line-height: ${24 / 15}; 
    font-family: ${fontFamily}; 
  }
  .bodyMedium { 
    font-size: 15px; 
    font-weight: 500; 
    letter-spacing: 0; 
    line-height: ${24 / 15}; 
    font-family: ${fontFamily}; 
  }
  .bodySmall { 
    font-size: 15px; 
    font-weight: 400; 
    letter-spacing: 0; 
    line-height: ${24 / 15}; 
    font-family: ${fontFamily}; 
  }
  .labelLarge { 
    font-size: 14px; 
    font-weight: 600; 
    letter-spacing: 0; 
    line-height: ${18 / 14}; 
    font-family: ${fontFamily}; 
  }
  .labelMedium { 
    font-size: 14px; 
    font-weight: 500; 
    letter-spacing: 0; 
    line-height: ${18 / 14}; 
    font-family: ${fontFamily}; 
  }
  .labelSmall { 
    font-size: 14px; 
    font-weight: 400; 
    letter-spacing: 0; 
    line-height: ${18 / 14}; 
    font-family: ${fontFamily}; 
  }
  .displayMedium { 
    font-size: 12px; 
    font-weight: 500; 
    letter-spacing: 0; 
    line-height: ${16 / 12}; 
    font-family: ${fontFamily}; 
  }
  .displaySmall { 
    font-size: 12px; 
    font-weight: 400; 
    letter-spacing: 0; 
    line-height: ${16 / 12}; 
    font-family: ${fontFamily}; 
  }
  .headlineMedium { 
    font-size: 10px; 
    font-weight: 500; 
    letter-spacing: 1px; 
    line-height: ${12 / 10}; 
    font-family: ${fontFamily}; 
  }
  .minorText { 
    font-size: 10px; 
    font-weight: 400; 
    letter-spacing: 0; 
    line-height: ${18 / 10}; 
    font-family: ${fontFamily}; 
  }
  
`;
