import {
  CursorDiv,
  CustomRow,
  CustomText,
  Divider,
  InfoBox,
  ScrollableFlexGrow,
  PaddingComponent,
} from "components/components";
import { CustomColumn } from "components/components";
import Image from "components/Image";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

const MyTeamsTile = ({ team, index, showPoints }) => {
  const teamTokens = team.units;
  const history = useHistory();

  return (
    <CursorDiv
      width="100%"
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/user_team/${team.id}`);
      }}
    >
      <InfoBox margin="8px 16px" padding="0px" background="var(--elevation-1)">
        <CustomColumn alignItems="flex-start">
          <Header index={index} />
          <Divider borderColor="var(--border-light)" />
          <CustomRow
            alignItems="center"
            padding="12px 0 12px 12px"
            gap="12px"
            width="100%"
          >
            {showPoints && <TeamPointsTile team={team} />}
            <ScrollableFlexGrow>
              <div
                style={{
                  gap: "8px",
                  display: "flex",
                  flexDirection: "row",
                  whiteSpace: "nowrap",
                }}
              >
                {teamTokens.map((token, index) => (
                  <SelectedBoosterTile key={index} token={token} />
                ))}
              </div>
            </ScrollableFlexGrow>
            <PaddingComponent width="12px" />
          </CustomRow>
        </CustomColumn>
      </InfoBox>
    </CursorDiv>
  );
};

const TeamPointsTile = ({ team }) => {
  return (
    <CustomColumn alignItems="flex-start" width="120px">
      <CustomText text="Points" className="labelSmall" color="var(--text-2)" />
      <CustomText text={team?.total_score?.toFixed(2)} className="titleLarge" />
    </CustomColumn>
  );
};

const Header = ({ index }) => {
  return (
    <div
      style={{
        background: "var(--elevation-1)",
        padding: "2px 16px",
        width: "calc(100% - 32px)",
      }}
    >
      <CustomRow justifyContent="space-between" alignItems="center">
        <CustomText text={`Team ${index + 1}`} className="bodySmall" />
      </CustomRow>
    </div>
  );
};

const ItemContainer = styled.div`
  flex: 0 0 auto; // Prevent items from shrinking or growing
  width: 48px; // Set a fixed width for each item, adjust as necessary
`;

export const SelectedBoosterTile = ({ token, isShowingBooster = true }) => {
  const booster =
    token?.points === 3 ? "3x" : token?.points === 2 ? "2x" : "1.5x";

  return (
    <ItemContainer
      onClick={(e) => {
        if (token?.page_url && !isShowingBooster) {
          e.stopPropagation();
          window.open(token?.page_url, "_blank");
        }
      }}
    >
      <CustomColumn>
        <Image
          src={token?.logo_url}
          style={{
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            objectFit: "cover",
          }}
        />
        <PaddingComponent height="4px" />
        <CustomText text={token?.name} className="bodySmall" />
        {token?.points === 0 || !isShowingBooster ? null : (
          <CustomText
            text={booster}
            className="labelSmall"
            color="var(--text-3)"
          />
        )}
      </CustomColumn>
    </ItemContainer>
  );
};

export default MyTeamsTile;
