import GlobalLeaderboardTile from "./global_leaderboard_tile";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  FlexBox,
  PaddingComponent,
} from "components/components";
import { getGlobalLeaderboard } from "api/contests.api";
import withLoader from "shared/withLoader";
import AppBar from "components/appbar";

const GlobalLeaderboardPage = ({ queryData }) => {
  const leaderboard = queryData?.data || [];
  return (
    <CustomColumn>
      <AppBar title="Global Leaderboard" />
      <LeaderboardHeader leaderboard={leaderboard} />
      <Divider />
      {leaderboard.map((item, index) => (
        <GlobalLeaderboardTile key={index} item={item} index={index} />
      ))}
      <PaddingComponent height="120px" />
    </CustomColumn>
  );
};

const LeaderboardHeader = () => {
  return (
    <CustomRow padding="12px 16px">
      <FlexBox flex="2">
        <CustomRow justifyContent="flex-start">
          <CustomText
            text={`RANK`}
            className="labelSmall"
            color="var(--text-3)"
          />
        </CustomRow>
      </FlexBox>
      <FlexBox flex="1">
        <CustomRow alignItems="flex-end" justifyContent="flex-end">
          <CustomText
            text="WINNINGS"
            className="labelSmall"
            color="var(--text-3)"
          />
        </CustomRow>
      </FlexBox>
    </CustomRow>
  );
};

export default withLoader(GlobalLeaderboardPage, getGlobalLeaderboard, {
  queryKey: () => ["leaderboard"],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
