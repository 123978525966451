import classes from "./loader.module.css";

const Loader = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        width: "100%",
      }}
    >
      <img
        src={require("assets/logo.png")}
        alt="loader"
        className={classes.loader}
      />
    </div>
  );
};

export default Loader;
