import { AuthContext } from "contexts/auth_context";
import React, { useContext } from "react";
import LoginPage from "./login";

const LoginCheckWrapper = ({ children }) => {
  const { isUserLoggedIn } = useContext(AuthContext);
  if (isUserLoggedIn) {
    return children;
  }
  return <LoginPage />;
};

export default LoginCheckWrapper;
