import React, { createContext, useState, useCallback } from "react";
import { clearAllQueryCache } from "utils/auth_utils";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { usePrivy } from "@privy-io/react-auth";

export const AuthContext = createContext({});

const AuthContextProvider = (props) => {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(false);
  const queryClient = useQueryClient();
  const { logout: logoutPrivy, authenticated } = usePrivy();

  const history = useHistory();

  const isUserAuthenticated = useCallback(
    async ({ isNavigating = false } = {}) => {
      //add timeout of 3 seconds
      let desirableRootPath = "/";
      if (!authenticated) {
        setIsUserLoggedIn(false);
        return "/signin";
      }
      setIsUserLoggedIn(true);
      desirableRootPath = "/home";
      if (isNavigating) {
        history.push(desirableRootPath);
      }
      return desirableRootPath;
    },
    [setIsUserLoggedIn, history, authenticated]
  );

  const logout = useCallback(
    async ({ isNavigating = true } = {}) => {
      await logoutPrivy();
      setIsUserLoggedIn(false);
      if (isNavigating) {
        history.push("/signin");
      }
      clearAllQueryCache(queryClient);
    },
    [history, queryClient, setIsUserLoggedIn, logoutPrivy]
  );

  return (
    <AuthContext.Provider
      value={{
        isUserLoggedIn,
        isUserAuthenticated,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
