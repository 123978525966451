import ContestWinningsTile from "./contest_winnings_tile";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  FlexBox,
  FlexGrow,
  PaddingComponent,
} from "components/components";
import withLoader from "shared/withLoader";
import { getWinnings } from "api/contests.api";

const ContestWinningsPage = ({ queryData }) => {
  const winnings = queryData?.data || [];
  return (
    <CustomColumn>
      <ContestWinningsHeader />
      <Divider />
      {winnings.map((winning, index) => (
        <ContestWinningsTile key={index} winning={winning} />
      ))}
      <PaddingComponent height="120px" />
    </CustomColumn>
  );
};

const ContestWinningsHeader = () => {
  return (
    <CustomRow padding="12px 16px">
      <FlexGrow>
        <CustomRow justifyContent="flex-start">
          <CustomText
            text="Rank"
            className="labelSmall"
            color="var(--text-3)"
          />
        </CustomRow>
      </FlexGrow>
      <FlexBox flex="1">
        <CustomRow alignItems="flex-end" justifyContent="flex-end">
          <CustomText
            text="Winnings"
            className="labelSmall"
            color="var(--text-3)"
          />
        </CustomRow>
      </FlexBox>
    </CustomRow>
  );
};

export default withLoader(ContestWinningsPage, getWinnings, {
  queryKey: ({ contestId }) => ["challenges", "winnings", contestId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
