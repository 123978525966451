export const faqsList = [
  {
    question: "How do I participate in a challenge?",
    answer:
      "To participate in a challenge, you need to create a team with your high conviction tokens and join contest to compete with other users.",
  },
  {
    question: "Can i join multiple contests with same team?",
    answer:
      "Yes, you can join multiple contests with same team and compete with other users.",
  },
  {
    question: "Can I join contest with multiple teams?",
    answer: "Yes, you can join contest with multiple teams.",
  },
  {
    question: "How do I fund my wallet to join a contest?",
    answer:
      "You can fund your wallet by depositing USDC on Base Chain Network.",
  },
  {
    question: "When will I get my winnings?",
    answer:
      "You will get your winnings after the contest ends. Generally, it can take few hours to credit your winnings.",
  },
  {
    question: "Can I change my team after joining a contest?",
    answer:
      "No you can't change your team after joining a contest. You can only change your team before joining a contest.",
  },
  {
    question: "How do I contact support?",
    answer: "You can contact us on Telegram or Twitter for any support.",
  },
];
