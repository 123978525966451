import React from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const GambitTooltip = ({ delay, ...props }) => {
  const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip
      enterDelay={delay}
      enterNextDelay={delay}
      {...props}
      classes={{ popper: className }}
    />
  ))(() => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#23242790",
      backdropFilter: "blur(10px)",
      boxShadow: "none !important",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#23242790",
      backdropFilter: "blur(10px)",
      fontFamily: "Work Sans",
      fontSize: "13px",
    },
  }));
  return <CustomTooltip {...props}>{props?.children}</CustomTooltip>;
};

export default GambitTooltip;
