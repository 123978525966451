import ContestPlayerLeaderboardTile from "./contest_player_leaderboard_tile";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  Divider,
  FlexBox,
  PaddingComponent,
} from "components/components";
import { getLeaderboard } from "api/contests.api";
import withLoader from "shared/withLoader";

const ContestLeaderboardPage = ({ queryData }) => {
  const leaderboard = queryData?.data || [];
  return (
    <CustomColumn>
      <ContestLeaderboardHeader leaderboard={leaderboard} />
      <Divider />
      {leaderboard.map((item, index) => (
        <ContestPlayerLeaderboardTile key={index} item={item} />
      ))}
      <PaddingComponent height="120px" />
    </CustomColumn>
  );
};

const ContestLeaderboardHeader = ({ leaderboard }) => {
  return (
    <CustomRow padding="12px 16px">
      <FlexBox flex="2">
        <CustomRow justifyContent="flex-start">
          <CustomText
            text={`All Teams (${leaderboard.length})`}
            className="labelSmall"
            color="var(--text-3)"
          />
        </CustomRow>
      </FlexBox>
      <FlexBox flex="1">
        <CustomText
          text="Points"
          className="labelSmall"
          color="var(--text-3)"
        />
      </FlexBox>
      <FlexBox flex="1">
        <CustomRow alignItems="flex-end" justifyContent="flex-end">
          <CustomText
            text="Rank"
            className="labelSmall"
            color="var(--text-3)"
          />
        </CustomRow>
      </FlexBox>
    </CustomRow>
  );
};

export default withLoader(ContestLeaderboardPage, getLeaderboard, {
  queryKey: ({ contestId }) => ["challenges", "leaderboard", contestId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
