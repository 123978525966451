import { usePrivy, useWallets } from "@privy-io/react-auth";
import { useState, useContext } from "react";
import { getTxnData } from "modules/privy/privy_utils";
import { joinContest } from "api/contests.api";
import { GlobalContext } from "contexts/global_context";
import { useHistory } from "react-router-dom";
import { SecondaryStyledButton } from "components/buttons";
import { getContestDetails } from "api/contests.api";
import { getUserBalance } from "api/user.api";
import FundWalletPopUp from "modules/privy/fund_wallet";

const JoinContestWrapper = ({ contestId, selectedTeams }) => {
  const [isLoading, setIsLoading] = useState(false);
  const { sendTransaction } = usePrivy();
  const { wallets } = useWallets();
  const { handleErrorSnackbar, handleSuccessSnackbar } =
    useContext(GlobalContext);
  const [isOpen, setIsOpen] = useState(false);

  const history = useHistory();

  const fetchContestDetails = async () => {
    try {
      const response = await getContestDetails({ contestId });
      return response;
    } catch (e) {
      handleErrorSnackbar(null, "Failed to fetch contest details");
    }
  };

  const checkIfUserHasBalance = async () => {
    try {
      const response = await getUserBalance();
      return response;
    } catch (e) {
      handleErrorSnackbar(null, "Failed to fetch user balance");
    }
  };

  const sendUSDC = async () => {
    try {
      setIsLoading(true);
      const contestDetails = await fetchContestDetails();
      if (!contestDetails) {
        throw new Error("Failed to fetch contest details");
      }
      const userBalance = await checkIfUserHasBalance();
      if (userBalance === null) {
        throw new Error("Failed to fetch user balance");
      }
      const amountRequired = contestDetails.joining_fee * selectedTeams.length;
      if (userBalance < amountRequired) {
        setIsOpen(true);
        throw new Error("Insufficient balance to join contest");
      }
      if (!amountRequired) {
        setIsLoading(false);
        return;
      }
      const requestData = await getTxnData(amountRequired, wallets);
      const uiConfig = {
        title: "Approve USDC",
        description: "Approve USDC to join the contest",
        buttonText: `Send ${amountRequired} USDC`,
        successDescription: "USDC sent successfully",
        transactionInfo: {
          title: "Join Contest",
        },
      };

      const txReceipt = await sendTransaction(requestData, uiConfig);
      if (!txReceipt) {
        setIsLoading(false);
        return;
      }
      handleSuccessSnackbar(
        "Transaction sent successfully! Waiting for confirmation"
      );
      await joinContestAPI({
        retryCount: 0,
        contestDetails,
        txnId: txReceipt.transactionHash,
      });
      // The returned `txReceipt` has the type `TransactionReceipt`
    } catch (error) {
      handleErrorSnackbar(null, getErrorMessage(error));
      setIsLoading(false);
    }
  };

  const getErrorMessage = (e) => {
    if (e.code === "UNPREDICTABLE_GAS_LIMIT") {
      return "Insufficient balance or gas to pay for the transaction";
    }
    return e.reason || e.message || "Transaction failed";
  };

  const joinContestAPI = async ({ contestDetails, retryCount = 0, txnId }) => {
    try {
      setIsLoading(true);
      const response = await joinContest({
        contestId: contestDetails.id,
        teamIds: selectedTeams.map((team) => team.id),
        txnId: txnId,
      });
      if (response.status === 200) {
        handleSuccessSnackbar("Joined contest successfully");
        history.replace(
          `/challenge/${contestDetails.challenge_id}/contest_details/${contestDetails.id}`
        );
        return;
      }
      setIsLoading(false);
    } catch (e) {
      if (retryCount < 3 && e.response?.status >= 500) {
        return joinContestAPI({
          retryCount: retryCount + 1,
          contestDetails,
        });
      }
      const error = e.response?.data?.detail || e.message;
      //rethrow error
      throw new Error(error);
    }
  };

  return (
    <>
      <SecondaryStyledButton
        isLoading={isLoading}
        onClick={(e) => {
          e.stopPropagation();
          if (selectedTeams.length < 1) {
            handleErrorSnackbar(null, "Please select at least 1 team");
            return;
          }
          sendUSDC();
        }}
      >
        Join Contest
      </SecondaryStyledButton>
      {isOpen && (
        <FundWalletPopUp
          open={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </>
  );
};

export default JoinContestWrapper;
