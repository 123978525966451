import {
  CustomColumn,
  CustomText,
  FlexGrow,
  PaddingComponent,
} from "./components";

const EmptyState = ({ title, subtitle, children }) => {
  return (
    <CustomColumn justifyContent="center" alignItems="center" gap="16px">
      <PaddingComponent height="36px" />
      <FlexGrow />
      <img
        src={require("assets/pepe.png")}
        alt="Empty State"
        style={{
          width: "100px",
          maxWidth: "100px",
          height: "auto",
          objectFit: "contain",
        }}
      />
      <CustomText text={title} className="bodyMedium" />
      {subtitle && (
        <CustomText
          text={subtitle}
          className="labelSmall"
          color="var(--text-2)"
        />
      )}
      {children}
      <FlexGrow />
    </CustomColumn>
  );
};

export default EmptyState;
