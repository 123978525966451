import "./App.css";
import GlobalContextProvider, { GlobalContext } from "contexts/global_context";
import AuthContext from "contexts/auth_context";
import React, { useContext } from "react";
import Routes from "routes/routes";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import ThemeContextProvider from "contexts/theme_context";
import { TextTheme } from "components/text_theme";
import SnackbarPopUp from "shared/snackbar";
import { PrivyProvider } from "@privy-io/react-auth";
import { privyAppId } from "utils/constants";
import { usePrivy } from "@privy-io/react-auth";
import FullPageLoader from "components/full_page_loader";
import { base } from "viem/chains";

function App() {
  const queryClient = new QueryClient();
  const { snackBarState } = useContext(GlobalContext);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <ThemeContextProvider>
          <BrowserRouter>
            <PrivyProvider
              appId={privyAppId}
              clientId={"client-WY5axWNdcB2Fp945dvpzRMFAgDNHazFJbVKCat4pLeVyt"}
              supportedChains={[base]}
              defaultChain={base}
              onSuccess={(_) => {}}
            >
              <GlobalContextProvider>
                <AuthContext>
                  <TextTheme />
                  <AppWrapper />
                  <SnackbarPopUp snackBarState={snackBarState} />
                </AuthContext>
              </GlobalContextProvider>
            </PrivyProvider>
          </BrowserRouter>
        </ThemeContextProvider>
      </QueryClientProvider>
    </div>
  );
}

const AppWrapper = () => {
  const { ready } = usePrivy();
  if (!ready) {
    return <FullPageLoader />;
  }
  return <Routes />;
};

export default App;
