import { PrimarySmallButton } from "components/buttons";
import {
  CursorDiv,
  CustomRow,
  CustomText,
  InfoBox,
  PaddingComponent,
} from "components/components";
import { CustomColumn } from "components/components";
import { useHistory } from "react-router-dom";
import ProgressBar from "components/progress_bar";
import React from "react";

const ContestTile = ({ contest, challengeData }) => {
  const history = useHistory();
  const spotsFilled = contest?.entry_spots - contest?.spots_left;
  const percent = (spotsFilled / contest?.entry_spots) * 100;
  return (
    <CursorDiv width="100%">
      <InfoBox
        margin="8px 16px"
        background="var(--elevation-1)"
        width="calc(100% - 32px)"
        onClick={(e) => {
          e.stopPropagation();
          history.push(
            `/challenge/${contest?.challenge_id}/contest_details/${contest?.id}`
          );
        }}
      >
        <CustomColumn alignItems="flex-start">
          <ContestHeader contest={contest} />
          <PaddingComponent height="8px" />
          <ContestPoolDetails contest={contest} challengeData={challengeData} />
          <PaddingComponent height="8px" />
          <ProgressBar percent={percent} />
          <PaddingComponent height="4px" />
          <ContestSpotsDetails contest={contest} />
        </CustomColumn>
      </InfoBox>
    </CursorDiv>
  );
};

const ContestHeader = ({ contest }) => {
  return (
    <CustomRow width="100%" alignItems="center" justifyContent="space-between">
      <CustomText
        className="labelSmall"
        text="Prize Pool"
        color="var(--text-1)"
      />
      <CustomRow gap="4px">
        <CustomText
          className="labelSmall"
          color="var(--text-2)"
          text="Entry:"
        />
        <CustomText
          className="labelSmall"
          color="var(--text-1)"
          text={`$${contest?.joining_fee}`}
        />
      </CustomRow>
    </CustomRow>
  );
};

const ContestPoolDetails = ({ contest, challengeData }) => {
  const history = useHistory();
  const contestStartTime = new Date(challengeData?.start_time);

  const isContestStarted = contestStartTime < new Date();
  return (
    <CustomRow width="100%" alignItems="center" justifyContent="space-between">
      <CustomText
        className="titleLarge"
        text={`$${contest?.prize_pool}`}
        color="var(--text-1)"
      />
      <PrimarySmallButton
        disabled={contest?.spots_left === 0 || isContestStarted}
        onClick={(e) => {
          e.stopPropagation();
          history.push(
            `/challenge/${contest.challenge_id}/contest_details/${contest.id}/select_teams`
          );
        }}
      >
        Join
      </PrimarySmallButton>
    </CustomRow>
  );
};

const ContestSpotsDetails = ({ contest }) => {
  return (
    <CustomRow width="100%" alignItems="center" justifyContent="space-between">
      <CustomText
        className="bodySmall"
        text={`${contest?.spots_left} spots left`}
        color="var(--text-3)"
      />
      <CustomText
        className="bodySmall"
        text={`${contest.entry_spots} spots`}
        color="var(--text-3)"
      />
    </CustomRow>
  );
};

export default ContestTile;
