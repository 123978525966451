import { usePrivy, useWallets } from "@privy-io/react-auth";
import { useEffect, useState, useContext } from "react";
import Web3 from "web3";
import { saveSessionDetails, tsNow } from "utils/auth_utils";
import { AuthContext } from "contexts/auth_context";
import { CursorDiv, CustomText } from "components/components";

const ConnectWalletText = () => {
  const [, setIsReady] = useState(false);
  const { ready, login, signMessage } = usePrivy();
  const { wallets } = useWallets();
  const { isUserAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    setIsReady(ready);
  }, [ready]);

  const handleLogin = async () => {
    try {
      if (wallets.length > 0) {
        await handleSignMessage();
        return;
      }
      await login();
    } catch (e) {
      console.error(e);
    }
  };

  const handleSignMessage = async () => {
    try {
      const embeddedWallet = wallets.find(
        (wallet) => wallet.walletClientType === "privy"
      );
      const fromAddr = embeddedWallet.address;
      const web3 = new Web3();
      const { address: sessionAddress, privateKey: sessionPrivateKey } =
        web3.eth.accounts.create();

      const message = {
        app: "gambit",
        address: fromAddr,
        sessionAddress,
        ts: tsNow(),
      };
      const jsonMessage = JSON.stringify(message);
      //sign message
      const signature = await signMessage(jsonMessage);
      const sessionDetails = {
        tokenSerialized: JSON.stringify(message),
        tokenSignature: signature,
        userAddress: fromAddr,
        sessionAddress,
        sessionPrivateKey,
        walletType: "EVM",
      };
      await saveSessionDetails(sessionDetails);
      await isUserAuthenticated({
        isNavigating: true,
      });
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <CursorDiv>
      <CustomText
        text="Connect Wallet"
        onClick={handleLogin}
        className="bodySmall"
        color="var(--primary-color)"
      />
    </CursorDiv>
  );
};

export default ConnectWalletText;
