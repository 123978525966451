import React from "react";
import { CustomRow, CustomText, FlexGrow } from "components/components";

const ContestWinningsTile = ({ winning }) => {
  return (
    <div
      style={{
        cursor: "pointer",
        borderBottom: "1px solid var(--border-light)",
        padding: "12px 16px",
        backgroundColor: "var(--base)",
      }}
    >
      <CustomRow>
        <FlexGrow>
          <CustomRow justifyContent="flex-start">
            <CustomText text={`${winning.rank}`} className="bodyMedium" />
          </CustomRow>
        </FlexGrow>
        <CustomText text={`$${winning.amount}`} className="bodyMedium" />
      </CustomRow>
    </div>
  );
};

export default ContestWinningsTile;
