import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// import DynamicWalletButton from "modules/login/components/dynamic_wallet_button";

const ConnectWalletAlertDialog = ({ open, handleClose }) => {
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Connect Wallet</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Please connect your wallet to continue.
          </DialogContentText>
        </DialogContent>
        <DialogActions>{/* <DynamicWalletButton /> */}</DialogActions>
      </Dialog>
    </div>
  );
};

export default ConnectWalletAlertDialog;
