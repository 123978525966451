import Web3 from "web3";
import base58 from "bs58";
import nacl from "tweetnacl";
import { PublicKey } from "@solana/web3.js";

const appName = "Gambit";

export const clearAllQueryCache = (queryClient) => {
  queryClient.getQueryCache().clear();
};

export const STORAGE_KEYS = Object.fromEntries(
  [
    "tokenSerialized",
    "tokenSignature",
    "userAddress",
    "sessionAddress",
    "sessionPrivateKey",
    "walletType",
  ].map((k) => [k, k])
);

export const invalidateAllQueries = (queryClient) => {
  queryClient.getQueriesData().forEach((query) => {
    queryClient.invalidateQueries(query.queryKey);
  });
};

const clearAuthState = () => {
  Object.keys(STORAGE_KEYS).forEach((k) => {
    localStorage.removeItem(k);
  });
};

export const tsNow = () => parseInt(new Date().getTime() / 1000);

export const isAllAuthValidKeyAvailable = () => {
  return Object.keys(STORAGE_KEYS).every((k) => {
    return Boolean(localStorage.getItem(k));
  });
};

export const signInSolanaUtil = async ({
  onConnect,
  onSignSuccess,
  connect,
  connected,
  publicKey,
  signMessage,
  isLocalStorageChange = true,
}) => {
  if (!publicKey && !connected) {
    await connect();
  }
  onConnect?.();
  const web3 = new Web3();
  const { address: sessionAddress, privateKey: sessionPrivateKey } =
    web3.eth.accounts.create();
  const sessionTokenData = {
    app: appName,
    address: publicKey?.toBase58(),
    sessionAddress: sessionAddress,
    ts: tsNow(),
  };
  const tokenSerialized = JSON.stringify(sessionTokenData);
  const message = new TextEncoder().encode(tokenSerialized);
  const uint8arraySignature = await signMessage(message);
  const signature = base58.encode(uint8arraySignature);
  onSignSuccess?.(signature);

  const walletIsSigner = nacl.sign.detached.verify(
    message,
    uint8arraySignature,
    publicKey.toBuffer()
  );
  if (!walletIsSigner) {
    throw new Error(
      "Error signing session. Signer doesn't match connected wallet"
    );
  }

  //return session details here
  const sessionDetails = {
    tokenSerialized: tokenSerialized,
    tokenSignature: signature,
    userAddress: publicKey?.toBase58(),
    sessionAddress: sessionAddress,
    sessionPrivateKey: sessionPrivateKey,
    walletType: "SOLANA",
  };
  if (!isLocalStorageChange) {
    return sessionDetails;
  }
  clearAuthState();
  await saveSessionDetails(sessionDetails);

  return sessionDetails;
};

export const saveSessionDetails = async (sessionDetails) => {
  Object.keys(sessionDetails).forEach((k) => {
    if (!Object.keys(STORAGE_KEYS).includes(k)) {
      throw new Error(
        "Oops, we broke something! Taking a look, please try again."
      );
    }
    Object.entries(sessionDetails).forEach(([k, v]) => {
      localStorage.setItem(k, v);
    });
  });
};

export const isSolanaWallet = async ({ address }) => {
  try {
    const publicKey = new PublicKey(address);
    return await PublicKey.isOnCurve(publicKey);
  } catch (e) {
    return false;
  }
};

export const connectWallet = async ({ onConnect, onSignSuccess }) => {};
