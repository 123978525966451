import React from "react";
import {
  CursorDiv,
  CustomColumn,
  CustomRow,
  CustomText,
  FlexGrow,
  PaddingComponent,
} from "components/components";
import Image from "components/Image";
import Radio from "@mui/material/Radio";
import { abbreviateNumber } from "utils/number_utils";
import SelectBooster from "./select_booster_tile";
import { ArrowUpRight } from "@phosphor-icons/react";

const TeamPlayerTile = ({
  tokenDetails,
  handlePlayerSelection,
  isSelected,
  isBoosterTile = false,
  proBooster,
  semiBooster,
  miniBooster,
  handleBoosterSelection,
}) => {
  const formattedMCap = abbreviateNumber(tokenDetails?.market_cap, true);
  const formattedPrice = abbreviateNumber(tokenDetails?.price, true);
  const subtitle = `M.Cap: $${formattedMCap} Price: $${formattedPrice}`;

  return (
    <div
      onClick={() => {
        if (isBoosterTile) return;
        handlePlayerSelection(tokenDetails);
      }}
      style={{
        cursor: "pointer",
        borderBottom: "1px solid var(--border-light)",
        padding: "12px 16px",
        backgroundColor: isSelected ? "var(--elevation-1)" : "var(--base)",
      }}
    >
      <CustomRow>
        <Image
          src={tokenDetails?.logo_url}
          style={{
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            objectFit: "cover",
          }}
        />
        <PaddingComponent width="8px" />
        <FlexGrow>
          <CustomColumn
            alignItems="flex-start"
            justifyContent="center"
            height="48px"
          >
            <CustomRow alignItems="center" gap="4px">
              <CustomText text={tokenDetails?.name} className="bodyMedium" />
              <CursorDiv
                onClick={(e) => {
                  if (!tokenDetails?.page_url) return;
                  e.stopPropagation();
                  window.open(tokenDetails?.page_url, "_blank");
                }}
              >
                <ArrowUpRight size={18} color="var(--text-2)" />
              </CursorDiv>
            </CustomRow>
            <CustomText
              text={subtitle}
              className="labelSmall"
              color="var(--text-3)"
            />
          </CustomColumn>
        </FlexGrow>
        {isBoosterTile ? (
          <SelectBoosterTrailingTile
            tokenDetails={tokenDetails}
            handlePlayerSelection={handlePlayerSelection}
            proBooster={proBooster}
            semiBooster={semiBooster}
            miniBooster={miniBooster}
            handleBoosterSelection={handleBoosterSelection}
          />
        ) : (
          <TeamPlayerTrailingTile
            isSelected={isSelected}
            tokenDetails={tokenDetails}
            handlePlayerSelection={handlePlayerSelection}
          />
        )}
      </CustomRow>
    </div>
  );
};

const TeamPlayerTrailingTile = ({
  tokenDetails,
  handlePlayerSelection,
  isSelected,
}) => {
  return (
    <Radio
      checked={isSelected}
      onChange={() => {
        handlePlayerSelection(tokenDetails);
      }}
      value="create-team"
      name="radio-button-demo"
      sx={{
        color: "var(--text-1)",
        "&.Mui-checked": {
          color: "var(--secondary-color)",
        },
      }}
    />
  );
};

const SelectBoosterTrailingTile = ({
  tokenDetails,
  proBooster,
  semiBooster,
  miniBooster,
  handleBoosterSelection,
}) => {
  return (
    <>
      <SelectBooster
        text="3x"
        type="pro"
        className="labelSmall"
        color="var(--text-3)"
        isSelected={proBooster === tokenDetails}
        tokenDetails={tokenDetails}
        handleBoosterSelection={handleBoosterSelection}
      />
      <PaddingComponent width="8px" />
      <SelectBooster
        text="2x"
        type="semi"
        className="labelSmall"
        color="var(--text-3)"
        tokenDetails={tokenDetails}
        isSelected={semiBooster === tokenDetails}
        handleBoosterSelection={handleBoosterSelection}
      />
      <PaddingComponent width="8px" />
      <SelectBooster
        text="1.5x"
        type="mini"
        className="labelSmall"
        color="var(--text-3)"
        tokenDetails={tokenDetails}
        handleBoosterSelection={handleBoosterSelection}
        isSelected={miniBooster === tokenDetails}
      />
    </>
  );
};

export default TeamPlayerTile;
