import {
  ScrollingContainer,
  ColumnScrolling,
  PaddingComponent,
} from "components/components";
import AppBar from "components/appbar";
import { faqsList } from "./faqs_list";
import FaqTile from "./faq_tile";

const FaqsPage = () => {
  return (
    <ScrollingContainer>
      <AppBar title="FAQs" />
      <ColumnScrolling>
        {faqsList.map((faq, index) => (
          <FaqTile key={index} question={faq.question} answer={faq.answer} />
        ))}
        <PaddingComponent height="20px" />
      </ColumnScrolling>
    </ScrollingContainer>
  );
};

export default FaqsPage;
