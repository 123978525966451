import classes from "./generic_fallback.module.css";
import { CustomText } from "components/components";

const GenericFallBackUI = ({ containerClass, fullPage = false }) => {
  let style = {};
  if (fullPage) {
    style = {
      height: "100vh",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  } else {
    style = {
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    };
  }

  return (
    <div className={`${containerClass} ${classes.container}`} style={style}>
      <CustomText className="titleSmall" text="Uh Oh!" />
      <CustomText
        className="bodySmall"
        color="var(--text-2)"
        text="There seems to be an issue."
      />
    </div>
  );
};

export default GenericFallBackUI;
