import BottomBar from "components/bottom_bar";
import ChallengesPage from "modules/challenges/challenges_page";
import MyChallengesPage from "modules/my_challenges/my_challenges_page";
import { Route, Switch } from "react-router-dom";
import HomeHeader from "./home_header";
import UserProfilePage from "modules/user_profile/user_profile";

const HomePage = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
      }}
    >
      <HomeHeader />
      <Switch>
        <Route exact path="/home" component={ChallengesPage} />
        <Route path="/my-challenges" component={MyChallengesPage} />
        <Route path="/settings" component={UserProfilePage} />
      </Switch>
      <BottomBar />
    </div>
  );
};

export default HomePage;
