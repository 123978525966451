import React, { createContext, useEffect, useState } from "react";
import useTheme, { light, dark } from "customHooks/useTheme";

export const ThemeContext = createContext({});

const mq = window.matchMedia("(prefers-color-scheme: dark)");

const ThemeContextProvider = ({ children }) => {
  const [storedTheme, setStoredTheme] = useState("dark");
  const [systemTheme, setSystemTheme] = useState(mq.matches ? "dark" : "light");

  const theme = storedTheme === "system" ? systemTheme : storedTheme;

  useEffect(() => {
    const listener = (e) => {
      setSystemTheme(e.matches ? "dark" : "light");
    };
    mq.addEventListener("change", listener);
    return () => mq.removeEventListener("change", listener);
  }, []);

  useTheme(theme === "dark" ? dark : light);

  return (
    <ThemeContext.Provider value={{ theme, storedTheme, setStoredTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
