import React from "react";
import styled from "styled-components";

// Container for the entire progress bar
const ProgressBarContainer = styled.div`
  width: 100%;
  background-color: #e0e0df; // Light grey background color
  border-radius: 12px; // Rounded corners
  overflow: hidden; // Ensures the inner bar doesn't overflow the container
`;

// The actual progress bar indicating the percentage
const Progress = styled.div`
  height: 6px; // Height of the progress bar
  width: ${(props) => props.percent || "0%"};
  background-color: var(--primary-color); // Color for the completed portion
  border-radius: 12px 0 0 12px; // Rounded corners for the left side of the bar
  transition: width 0.3s ease-in-out; // Smooth transition for changing width
`;

const ProgressBar = ({ percent }) => {
  return (
    <ProgressBarContainer>
      <Progress percent={`${percent}%`} />
    </ProgressBarContainer>
  );
};

export default ProgressBar;
