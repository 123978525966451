import classes from "./components.module.css";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { CircularProgress } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Skeleton } from "@mui/material";
import { CaretLeft, CaretDown, CaretUp } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";
import { Box } from "@mui/material";
import ConditionalLink from "shared/conditional_link";
import Spinner from "shared/buttonloadingSpinner";

export const Divider = styled.div`
  ${(props) =>
    ` width:${props.width ?? "100%"};
  border-bottom: 1px solid ${props.borderColor ?? "var(--border-dark)"} ;
  box-sizing: border-box;
  display: flex;
  opacity:${props.opacity ?? "1"};
  ${props.height != null && `height: ${props.height}`};
  ${props.background != null && `background: ${props.background}`};
  ${props.borderRadius != null && `border-radius: ${props.borderRadius}`};
  `}
`;
export const VerticalDivider = styled.div`
  ${(props) =>
    `  border-left: 1px solid ${props.borderColor ?? "var(--border-dark)"} ;
  box-sizing: border-box;
  ${props.height != null && `height: ${props.height}`};
  `}
`;

export const InfoBox = styled.div`
  ${(props) =>
    `
    display: flex;
  flex-direction: column;
  ${props.alignItems != null && `align-items: ${props.alignItems}`};
  ${props.justifyContent != null && `justify-content: ${props.justifyContent}`};
  margin: ${props.margin ?? "0px"};
  height: ${props.height};
  padding:  ${props.padding ?? "16px 20px"};;
  background: ${props.background ?? "var(--base)"};
  border: ${props.borderWidth ?? "1px"} solid var(--border-dark);
  border-radius: ${props.radius ?? "6px"};
  font-family: "Work Sans";
    `}
`;
export const Container = styled.div`
  ${(props) =>
    ` margin: ${props.margin ?? "0px"};
  width: ${props.width};
  height: ${props.height};
  padding:  ${props.padding ?? "16px 20px"};;
    `}
`;

export const InfoBoxContainer = ({
  header,
  children,
  contentPaddingTop,
  headerPaddingBottom,
  padding = "16px 20px",
  hasHeaderBorder = true,
  headerBackground,
  paddingBottom = "16px",
  ...infoBoxProps
}) => {
  return (
    <>
      <InfoBox {...infoBoxProps} paddingTop="0" padding={padding}>
        {header && (
          <InfoBoxHeader
            contentPaddingTop={contentPaddingTop}
            headerPaddingBottom={headerPaddingBottom}
            hasHeaderBorder={hasHeaderBorder}
            background={headerBackground}
          >
            {header}
          </InfoBoxHeader>
        )}
        <div className={classes.infoBoxContent}>{children}</div>
      </InfoBox>
      {!!paddingBottom && (
        <PaddingComponent height={paddingBottom}></PaddingComponent>
      )}
    </>
  );
};

export const InfoBoxHeader = ({
  children,
  contentPaddingTop = "16px",
  headerPaddingBottom = "10px",
  hasHeaderBorder = true,
  background = "none",
}) => {
  return (
    <>
      <span className={classes.infoBoxHeader} style={{ background }}>
        {children}
      </span>
      <PaddingComponent height={headerPaddingBottom}></PaddingComponent>
      {hasHeaderBorder && <Divider />}
      <PaddingComponent height={contentPaddingTop}></PaddingComponent>
    </>
  );
};

export const TableHeader = ({ items }) => {
  return (
    <div className={classes.table}>
      <div className={`${classes.tableRow} ${classes.tableHeader}`}>
        {[...items].map((item) => (
          <div
            key={item.name}
            style={{
              flex: item.flex,
              textAlign: item.alignment ?? "left",
            }}
          >
            {item.name}
          </div>
        ))}
      </div>
    </div>
  );
};

export const Table = ({ children }) => {
  return <div className={classes.table}>{children}</div>;
};

export const TableRowTile = ({ onClick, link, children }) => {
  if (link) {
    return (
      <div className={`${classes.tableRow} ${classes.tableList}`}>
        <Link to={link} className={classes.tableRow}>
          {children}
        </Link>
      </div>
    );
  }
  return (
    <div className={`${classes.tableRow} ${classes.tableList}`}>
      <div onClick={onClick} className={classes.tableRow}>
        {children}
      </div>
    </div>
  );
};

export const TableRowItem = ({ alignment, flex, children, onClick }) => {
  alignment = alignment || "left";
  return (
    <div
      onClick={(e) => {
        if (onClick) {
          e.stopPropagation();
        }
      }}
      style={{
        height: "24px",
        flex: flex,
        width: "100%",
        textAlign: alignment,
        overflow: "hidden",
        whiteSpace: "nowrap",
      }}
    >
      {children}
    </div>
  );
};

export const PaddingComponent = styled.div`
  ${(props) =>
    `
      width : ${props.width};
      height : ${props.height};
      padding-top : ${props.paddingTop};
      padding-bottom: ${props.paddingBottom};
      padding-left: ${props.paddingLeft};
      padding-right: ${props.paddingRight};
      padding: ${props.padding};
      background-color: ${props.backgroundColor};
    `}
`;
export const CommonButton = styled.button`
  ${(props) =>
    `
      width : ${props.width};
      height : ${props.height};
      padding: ${props.padding};
      background:${props.background};
      color:${props.color ?? "var(--text-2)"};
      font-size:${props.fontSize ?? "12px"};
      ${!!props.fontWeight ? `font-weight: ${props.fontWeight};` : ""};
      border-radius:${props.borderRadius ?? "4px"};
    `}
`;

export const CustomColumn = styled.div`
  ${(props) =>
    `
      display: flex;
      flex-direction: column;
      width: ${props.width};
      justify-content: ${props.justifyContent};
      align-items: ${props.alignItems};
      gap: ${props.gap};
      background: ${props.background};
      ${props.padding != null && `padding: ${props.padding}`};
      ${props.borderRadius != null && `border-radius: ${props.borderRadius}`};
      ${props.border != null && `border: ${props.border}`};
      ${props.margin != null && `margin: ${props.margin}`};
      ${props.height != null && `height: ${props.height}`};
      ${props.maxWidth != null && `max-width: ${props.maxWidth}`};
      ${props.flexShrink != null && `flex-shrink: ${props.flexShrink}`};
      ${props.opacity != null && `opacity: ${props.opacity}`};
      ${props.cursor != null && `cursor: ${props.cursor}`};
      ${props.flexGrow != null && `flex-grow: ${props.flexGrow}`};
      overflow-x:${props.overflowX || "hidden"};
      overflow-y:${props.overflowY || "hidden"};
    `}
`;

export const CustomRow = styled.div`
  ${(props) =>
    `border-radius:${props.borderRadius};
      overflow-x:${props.overflowX || "hidden"};
      overflow-y:${props.overflowY || "hidden"};
      box-sizing: border-box;
      display: flex;
      width: ${props.width};
      ${props.height != null && `height: ${props.height}`};
      ${props.cursor != null && `cursor: ${props.cursor}`};
      background: ${props.background};
      flex-direction: row;
      justify-content: ${props.justifyContent};
      flex-grow: ${props.flexGrow};
      flex-shrink: ${props.flexShrink ?? `auto`};
      ${props.flexGrow != null && `flex-grow: ${props.flexGrow}`};
      align-items: ${props.alignItems};
      gap: ${props.gap};
      flex-wrap: ${props.flexWrap};
      ${props.padding != null && `padding: ${props.padding}`};
      ${props.border != null && `border: ${props.border}`};
      cursor:${props.cursor};
      ${props.flexBasis != null && `flex-basis:${props.flexBasis}`};
      ${props.maxWidth != null && `max-width:${props.maxWidth}`};
      ${props.alignSelf != null && `align-self:${props.alignSelf}`};
      ${props.margin != null && `margin:${props.margin}`};
      ${props.minWidth != null && `min-width:${props.minWidth}`};

      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }

      &::-webkit-scrollbar-thumb {
        background-color: transparent;
      }

      &:hover::-webkit-scrollbar-thumb {
        background-color: var(--text-3);
        border-radius: 20px;
      }

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    `}
`;

export const FlexGrow = styled.div`
  flex-grow: 1;
`;
export const ScrollableFlexGrow = styled.div`
  flex-grow: 1;
  overflow-x: auto;
  display: flex;
`;
export const ColumnScrolling = styled.div`
  flex-grow: 1;
  overflow-y: auto;
`;

export const ScrollingContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const FlexBox = styled.div`
  ${(props) =>
    `
      flex : ${props.flex};
      justify-content: flex-end;
      overflow:${props.overflow || "hidden"};
    `}
`;

export const TypeTag = ({ text, className }) => {
  return (
    <div className={`${classes.typeTag} ${className}`}>
      <span>{text}</span>
    </div>
  );
};

export const IconWrapper = styled.div`
  ${(props) => `
    cursor: ${props.cursor ?? "pointer"};
    onClick: ${props.onClick};
    width: ${props.width ?? "32px"};
    height: ${props.height ?? "32px"};
    box-sizing: border-box;
    border-radius: 50%;
    ${!props.noBorder ? "border: 1px solid var(--border-dark);" : ""};
    align-items: center;
    justify-content: center;
    display: flex;
    background: ${props.color};
  `}
`;

export const BulletPoint = styled.div`
  ${(props) => `
    width: ${props.width ?? "3px"};
    height: ${props.height ?? "3px"};
    margin: 0px 4px;
    box-sizing: border-box;
    border-radius: 50%;
    background:${props.bgColor ?? "var(--text - 2)"};
  `}
`;

export const CursorDiv = styled.div`
  ${(props) => `
    cursor: pointer;
  `}
`;

export const CustomCircularProgress = ({ size = 12, isColorWhite = false }) => {
  return (
    <CircularProgress
      className={
        isColorWhite ? classes.circularProgressWhite : classes.circularProgress
      }
      size={size}
    />
  );
};

export const SpinnerComponent = ({ size = 20, color = "var(--text-2)" }) => {
  return (
    <Spinner
      style={{
        background: "transparent",
        width: `${size}px`,
        height: `${size}px`,
        alignSelf: "center",
      }}
      stroke={color}
    />
  );
};

export const CustomButtonStyle = styled.div`
  ${(props) => `
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  onClick: ${props.onClick};
  background: ${props.background ?? "var(--primary-color)"};
  border-radius: ${props.radius ?? "24px"};
  padding: 4px 12px 4px 12px;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: ${props.fontWeight ?? "600"}};
  font-size: ${props.fontSize ?? "13px"}};
  line-height: 150%;
  text-align: center;
  color: ${props.color ?? "var(--base)"};
  `}
`;

export const CustomButton = ({ text, ...props }) => {
  return (
    <CustomButtonStyle {...props} className={props.className}>
      {text}
    </CustomButtonStyle>
  );
};

export const RedirectLink = ({ link, children, style, ...props }) => {
  return (
    <ConditionalLink
      sameTab={false}
      onClick={(e) => {
        e.stopPropagation();
      }}
      style={{
        color: "inherit",
        ...style,
      }}
      to={{ pathname: link }}
      {...props}
    >
      {children}
    </ConditionalLink>
  );
};

const customTheme = createTheme({
  components: {
    MuiSkeleton: {
      styleOverrides: {
        wave: {
          background:
            "linear-gradient(90deg, var(--shimmer1) 0%, var(--shimmer2) 100%)",
        },
      },
    },
  },
});

export const CustomSkeleton = ({ width, height = 24, variant = "text" }) => {
  return (
    <ThemeProvider theme={customTheme}>
      <Skeleton
        animation="wave"
        width={width}
        height={height}
        variant={variant}
      />
    </ThemeProvider>
  );
};
export const CustomCheckBox = ({
  isActive,
  isToggleable = true,
  setIsActive,
}) => {
  return (
    <input
      className={classes.checkbox}
      type="checkbox"
      disabled={!isToggleable}
      checked={isActive}
      onChange={() => {
        if (!isToggleable) return;
        setIsActive(!isActive);
      }}
    />
  );
};

export const CustomBackButton = ({ onClick, isShow }) => {
  const history = useHistory();

  if (
    (history.action !== "POP" && history.location.pathname !== "/home") ||
    isShow
  )
    return (
      <CaretLeft
        size={24}
        color="var(--text-1)"
        className={classes.arrow_back_icon}
        onClick={() => {
          if (onClick) {
            onClick();
          } else {
            history.goBack();
          }
        }}
      />
    );
  return null;
};

export const LoadingHoverCard = ({ minWidth = 300 }) => {
  return (
    <div
      className={classes.loadingHoverCard}
      style={{
        minWidth,
        display: "flex",
        alignItems: "center",
      }}
    >
      <CustomCircularProgress size={24} />
    </div>
  );
};

export const MaterialIconWrapper = ({ icon, size, color }) => {
  return (
    <Box
      component={icon}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        fontSize: `${size ?? "24px"}`,
        color: { color },
      }}
    />
  );
};

export const LinkReplace = ({ to, className, children }) => {
  const history = useHistory();
  return (
    <div className={className}>
      <CursorDiv
        onClick={() => {
          history.replace(to);
        }}
      >
        {children}
      </CursorDiv>
    </div>
  );
};

export const CustomTextSpan = styled.span`
  ${(props) => `
  font-family: ${props.fontFamily ?? "Work Sans"} ;
  white-space: ${props.whitespace};
  font-style: normal;
  font-weight: ${props.fontWeight ?? "400"};
  font-size: ${props.fontSize ?? "15px"};
  line-height: 150%;
  color: ${props.color ?? "var(--text-1)"};
  letter-spacing: ${props.letterSpacing ?? "-0.02em"};
  `}
`;

const multilineOverflow = (lines) => `
  display: -webkit-box;
  -webkit-line-clamp: ${lines}; 
  -webkit-box-orient: vertical;  
  overflow: hidden;
`;

export const CustomTextStyle = styled.div`
  ${(props) => `
  font-family: ${props.fontFamily ?? "Roboto"};
  font-style: normal;
  ${props?.whiteSpace ? `white-space: ${props.whiteSpace};` : ""}
  font-weight: ${props.fontWeight ?? "400"};
  font-size: ${props.fontSize ?? "13px"};
  line-height: ${props.lineHeight ?? "150%"};
  text-align: ${props.textAlign ?? "center"};
  letter-spacing: ${props.letterSpacing ?? "-0.02em"};
  color: ${props.color ?? "var(--text-1)"};
  text-transform: ${props.textTransform ?? "none"};
  border-radius: ${props.borderRadius ?? "0px"};
  background: ${props.background};
  ${props.padding != null ? `padding: ${props.padding};` : ""}
  ${props.width != null ? `width: ${props.width};` : ""}
  ${props.maxLines ? multilineOverflow(props.maxLines) : ""}
  `}
`;

export const CustomText = ({ text, ...props }) => {
  return (
    <CustomTextStyle className={props.className} {...props}>
      {text}
    </CustomTextStyle>
  );
};

export const RoundedChipUI = styled.div`
  ${(props) => `
  font-family: Work Sans;
  font-style: normal;
  padding: 1px 8px;
  color:  ${props.color ?? "var(--text-2)"};
  font-size: 13px;a
  font-family: Work Sans;
  font-weight: 400;
  line-height: 19.50px;
  word-wrap: break-word;
  background:${props.bgColor ?? "var(--elevation-1)"};
  border-radius:${props.borderRadius ?? "40px"};
  cursor: pointer;
  border:${props.noBorder ? "none" : "0.50px solid var(--border-dark)"};
  white-space: nowrap;
  `}
`;

export const RoundedChips = ({ text, ...props }) => {
  return <RoundedChipUI {...props}>{text}</RoundedChipUI>;
};

export const TransparentButton = styled.button`
  background: none;
  padding: 0;
`;

export const PriceChange = ({ value, displayValue, fontSize = "13px" }) => {
  const isNoChange = value === 0;
  const isPositive = value > 0;
  const color = isNoChange
    ? "var(--text-2)"
    : isPositive
    ? "var(--success)"
    : "var(--error)";

  return (
    <CustomRow alignItems="center" gap="2px">
      {isNoChange || displayValue == null ? null : isPositive ? (
        <CaretUp size={10} color={color} weight="fill" />
      ) : (
        <CaretDown size={10} color={color} weight="fill" />
      )}
      <CustomText
        text={displayValue}
        color={color}
        fontSize={fontSize}
        fontWeight="600"
      />
    </CustomRow>
  );
};

export const GradientDivider = styled.div`
  ${({
    startColor = "var(--gradient-divider-base-color-1)",
    endColor = "var(--gradient-divider-base-color-2)",
  }) => `
 width: 100%;
 border-width: 1px;
 border-style: solid;
 border-image: linear-gradient(to right, ${startColor}, ${endColor}) 1 1%;
 border-top: none;
 border-left: none;
 
 `}
`;

export const CircleAvatar = styled(Image)`
  ${(props) => `
  height: ${props?.height ?? "24px"};
  width: ${props?.width ?? "24px"};
  border: 1px solid var(--base);
  border-radius: ${props?.borderRadius ?? "50%"};
  overflow: hidden;
  ${props.flexShrink != null ? `flex-shrink: ${props.flexShrink};` : ""}
  ${props.objectFit != null ? `object-fit: ${props.objectFit};` : ""}
  `}
`;

export const HoverableDiv = styled.div`
  cursor: pointer;
  background-color: "var(--base)";

  &:hover {
    background-color: "red";
  }
`;
