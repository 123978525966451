import { signedRequest } from "./api";

export const getUserBalance = async () => {
  const response = await signedRequest({
    method: "get",
    path: `/api/user/get-balance`,
  });
  return response.data;
};

export const getDepositDetails = async () => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/deposit-details`,
  });
  return response.data;
};
