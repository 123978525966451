import React from "react";
import {
  CustomText,
  PaddingComponent,
  CustomRow,
  FlexGrow,
} from "components/components";
import { CaretLeft } from "@phosphor-icons/react";
import { useHistory } from "react-router-dom";

const AppBar = ({ title, onClick, children }) => {
  const history = useHistory();
  return (
    <div
      style={{
        borderBottom: "0.5px solid var(--border-light)",
        padding: "16px 0",
        position: "sticky",
        top: 0,
        zIndex: 1000,
      }}
    >
      <CustomRow>
        <PaddingComponent
          padding="0 10px"
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (onClick) {
              onClick();
              return;
            }
            history.goBack();
          }}
        >
          <CaretLeft size={20} color="var(--text-1)" />
        </PaddingComponent>
        <CustomText text={title} className="titleSmall" />
        <FlexGrow />
        {children}
      </CustomRow>
    </div>
  );
};

export default AppBar;
