import { CustomRow, CustomText, FlexGrow } from "components/components";
import logo from "assets/logo.png";
import ConnectWalletText from "modules/login/components/connect_wallet_text";
import { AuthContext } from "contexts/auth_context";
import { useContext } from "react";
import UserBalance from "./user_balance";

const HomeHeader = () => {
  const { isUserLoggedIn } = useContext(AuthContext);
  return (
    <div
      style={{
        borderBottom: "0.5px solid var(--border-light)",
        display: "flex",
        padding: "8px 20px",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <FlexGrow>
        <CustomRow alignItems="center">
          <img
            src={logo}
            alt="Gambit"
            style={{
              width: "28px",
              height: "28px",
              marginRight: "8px",
            }}
          />
          <div
            style={{
              borderRadius: "24px",
              width: "fit-content",
            }}
          >
            <CustomText
              text="Gambit"
              fontFamily="Sancreek"
              fontSize="24px"
              fontWeight="500"
              letterSpacing="2"
              lineHeight="34px"
            />
          </div>
        </CustomRow>
      </FlexGrow>
      {!isUserLoggedIn ? <ConnectWalletText /> : <UserBalance />}
    </div>
  );
};

export default HomeHeader;
