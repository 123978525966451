import { usePrivy } from "@privy-io/react-auth";
import { SecondaryStyledButton } from "components/buttons";
import { useEffect, useState, useContext } from "react";
import { AuthContext } from "contexts/auth_context";

const ConnectWalletButton = () => {
  const [isReady, setIsReady] = useState(false);
  const { ready, login, user } = usePrivy();
  const { isUserAuthenticated } = useContext(AuthContext);

  useEffect(() => {
    setIsReady(ready);
  }, [ready]);

  useEffect(() => {
    if (user) {
      isUserAuthenticated({
        isNavigating: true,
      });
    }
  }, [user, isUserAuthenticated]);

  const handleLogin = async () => {
    try {
      if (user) {
      }
      login();
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <SecondaryStyledButton isLoading={!isReady} onClick={handleLogin}>
      Connect Wallet
    </SecondaryStyledButton>
  );
};

export default ConnectWalletButton;
