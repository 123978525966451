import { PrimaryStyledButton } from "components/buttons";
import {
  CursorDiv,
  CustomRow,
  CustomText,
  InfoBox,
  PaddingComponent,
} from "components/components";
import { CustomColumn } from "components/components";
import { useHistory } from "react-router-dom";
import ProgressBar from "components/progress_bar";
import { abbreviateNumber } from "utils/number_utils";
import { useQuery } from "@tanstack/react-query";
import { getChallengeDetails } from "api/challenges.api";

const ContestDetailsTile = ({ contest, challengeId }) => {
  const history = useHistory();
  const spotsFilled = contest.entry_spots - contest.spots_left;
  const percent = (spotsFilled / contest?.entry_spots) * 100;

  const { data } = useQuery({
    queryKey: ["user_balance"],
    queryFn: () => getChallengeDetails({ challengeId }),
    staleTime: 100,
    retry: 3,
  });

  const contestStartTime = new Date(data?.start_time);

  const isContestStarted = contestStartTime < new Date();

  return (
    <CursorDiv width="100%">
      <InfoBox
        margin="8px 16px"
        background="var(--elevation-1)"
        width="calc(100% - 32px)"
      >
        <CustomColumn alignItems="flex-start">
          <ContestHeader />
          <PaddingComponent height="8px" />
          <ContestPoolDetails contest={contest} />
          <PaddingComponent height="8px" />
          <ProgressBar percent={percent} />
          <PaddingComponent height="4px" />
          <ContestSpotsDetails contest={contest} />
          <PaddingComponent height="8px" />
          <PrimaryStyledButton
            isLoginRequired={true}
            disabled={contest.spots_left === 0 || isContestStarted}
            onClick={(e) => {
              e.stopPropagation();
              history.push(
                `/challenge/${contest.challenge_id}/contest_details/${contest.id}/select_teams`
              );
            }}
          >
            Join
          </PrimaryStyledButton>
        </CustomColumn>
      </InfoBox>
    </CursorDiv>
  );
};

const ContestHeader = () => {
  return (
    <CustomRow width="100%" alignItems="center" justifyContent="space-between">
      <CustomText
        className="labelSmall"
        text="Current Prize Pool"
        color="var(--text-1)"
      />
      <CustomText
        className="labelSmall"
        color="var(--text-1)"
        text="Total Prize Pool"
      />
    </CustomRow>
  );
};

const ContestPoolDetails = ({ contest }) => {
  const totalPrizePool = abbreviateNumber(
    contest.entry_spots * contest.joining_fee,
    true
  );
  const spotsFilled = contest.entry_spots - contest.spots_left;

  const currentPrizePool = abbreviateNumber(
    spotsFilled * contest.joining_fee,
    true
  );

  return (
    <CustomRow width="100%" alignItems="center" justifyContent="space-between">
      <CustomText
        className="titleLarge"
        text={`$${currentPrizePool}`}
        color="var(--text-1)"
      />
      <CustomText
        className="titleLarge"
        text={`$${totalPrizePool}`}
        color="var(--text-1)"
      />
    </CustomRow>
  );
};

const ContestSpotsDetails = ({ contest }) => {
  return (
    <CustomRow width="100%" alignItems="center" justifyContent="space-between">
      <CustomText
        className="bodySmall"
        text={`${contest.spots_left} spots left`}
        color="var(--text-3)"
      />
      <CustomText
        className="bodySmall"
        text={`${contest?.entry_spots} spots`}
        color="var(--text-3)"
      />
    </CustomRow>
  );
};

export default ContestDetailsTile;
