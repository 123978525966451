import StickyTabs from "components/sticky_tabs";
import React, { useState } from "react";
import MyChallengesList from "./my_challenges_list";
import { MyChallengesType } from "helpers/custom_enums";

const MyChallengesPage = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div
      style={{
        height: "100%",
      }}
    >
      <StickyTabs
        value={value}
        handleChange={handleChange}
        tabs={["Live", "Upcoming", "Completed"]}
      />
      <div
        style={{
          height: "100%",
        }}
      >
        {value === 0 && (
          <MyChallengesList challengeType={MyChallengesType.LIVE} />
        )}
        {value === 1 && (
          <MyChallengesList challengeType={MyChallengesType.UPCOMING} />
        )}
        {value === 2 && (
          <MyChallengesList challengeType={MyChallengesType.COMPLETED} />
        )}
      </div>
    </div>
  );
};

export default MyChallengesPage;
