import styles from "./refetch.module.css";
import RefreshIcon from "@mui/icons-material/Refresh";
import { CustomText, CustomRow, PaddingComponent } from "components/components";

const Refetch = ({
  onClick,
  text,
  containerClass = "",
  margin = "0px",
  showHeader = true,
  buttonText = "Reload",
  showText = true,
}) => {
  return (
    <div
      className={`${styles.refetch} ${containerClass}`}
      onClick={onClick}
      style={{ margin }}
    >
      {showHeader && <CustomText className="titleSmall" text="Uh Oh!" />}
      {showText && (
        <CustomText
          className="bodySmall"
          text={text || "There seems to be an issue."}
          color="var(--text-2)"
        />
      )}
      <PaddingComponent height="8px" />
      <CustomRow gap="4px">
        <RefreshIcon className={styles.refresh_icon}></RefreshIcon>
        <CustomText
          className="bodySmall"
          text={buttonText}
          color="var(--secondary-color)"
        />
      </CustomRow>
    </div>
  );
};

export default Refetch;
