import {
  CursorDiv,
  CustomColumn,
  CustomRow,
  CustomText,
  FlexGrow,
  InfoBox,
} from "components/components";
import { CaretDown, CaretUp } from "@phosphor-icons/react";
import { useState } from "react";

const FaqTile = ({ question, answer }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <CursorDiv>
      <InfoBox
        margin="12px 20px"
        padding="12px 16px"
        onClick={() => {
          setIsExpanded(!isExpanded);
        }}
      >
        <CustomColumn alignItems="flex-start" gap="8px">
          <CustomRow width="100%">
            <FlexGrow>
              <CustomRow width="100%" justifyContent="flex-start">
                <CustomText text={question} className="bodyMedium" />
              </CustomRow>
            </FlexGrow>
            {isExpanded ? (
              <CaretUp
                onClick={() => setIsExpanded(false)}
                color="var(--text-2)"
                size="24px"
              />
            ) : (
              <CaretDown
                size="24px"
                onClick={() => setIsExpanded(true)}
                color="var(--text-2)"
              />
            )}
          </CustomRow>
          {isExpanded && (
            <CustomText
              text={answer}
              textAlign="left"
              className="labelSmall"
              color="var(--text-2)"
            />
          )}
        </CustomColumn>
      </InfoBox>
    </CursorDiv>
  );
};

export default FaqTile;
