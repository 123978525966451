import { PrimaryStyledButton } from "components/buttons";
import { PaddingComponent } from "components/components";
import EmptyState from "components/empty_state";
import { useHistory } from "react-router-dom";

const PageNotFound = () => {
  const history = useHistory();
  return (
    <EmptyState
      title="Oops, its 404!"
      subtitle="The page you are looking for does not exist."
    >
      <PaddingComponent padding="12px">
        <PrimaryStyledButton
          width="200px"
          onClick={() => {
            history.replace("/home");
          }}
        >
          Go to Home
        </PrimaryStyledButton>
      </PaddingComponent>
    </EmptyState>
  );
};

export default PageNotFound;
