import ChallengeTile from "modules/challenges/challenge_tile";
import {
  CustomColumn,
  PaddingComponent,
  ScrollingContainer,
  ColumnScrolling,
} from "components/components";
import EmptyState from "components/empty_state";
import React from "react";
import withLoader from "shared/withLoader";
import { myChallenges } from "api/challenges.api";
import { filterChallenges } from "./challenge_utils";

const MyChallengesList = ({ challengeType, queryData }) => {
  const challenges = filterChallenges(queryData, challengeType);
  if (challenges.length === 0) {
    return (
      <EmptyState
        title="You have not participated in any challenges yet."
        subtitle="Participate in challenges to win big."
      />
    );
  }
  return (
    <ScrollingContainer>
      <ColumnScrolling>
        <CustomColumn>
          {challenges.map((challenge, index) => (
            <ChallengeTile key={index} challenge={challenge} />
          ))}
          <PaddingComponent height="120px" />
        </CustomColumn>
      </ColumnScrolling>
    </ScrollingContainer>
  );
};

export default withLoader(MyChallengesList, myChallenges, {
  queryKey: ({ challengeType }) => ["my-challenges", challengeType],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
