import AppBar from "components/appbar";
import {
  PaddingComponent,
  ScrollingContainer,
  ColumnScrolling,
  CustomColumn,
  CustomRow,
  CustomText,
} from "components/components";
import { Box } from "@mui/material";
import CustomTextField from "components/custom_text_field.styled";
import { useState } from "react";
import { PrimaryStyledButton } from "components/buttons";

const DepositPage = () => {
  const [amount, setAmount] = useState("");

  return (
    <ScrollingContainer>
      <ColumnScrolling>
        <Box>
          <AppBar title="Withdraw" />
          <CustomColumn padding="16px">
            <WalletBalance />
            <PaddingComponent height="20px" />
            <CustomTextField
              variant="outlined"
              type="text"
              label="Enter amount($)"
              className="textField"
              value={amount}
              onChange={(event) => {
                setAmount(event.target.value);
              }}
            />
            <PaddingComponent height="24px" />
            <PrimaryStyledButton>Withdraw</PrimaryStyledButton>
            <PaddingComponent height="16px" />
            <TalkToUs />
            <PaddingComponent height="16px" />
          </CustomColumn>
        </Box>
      </ColumnScrolling>
    </ScrollingContainer>
  );
};

const TalkToUs = () => {
  return (
    <div
      onClick={() => {
        window.open("https://t.me/krraigs", "_blank");
      }}
      style={{
        cursor: "pointer",
        padding: "8px",
      }}
    >
      <CustomText
        text="Have doubts? Talk to us"
        className="labelSmall"
        color="var(--text-2)"
      />
    </div>
  );
};

const WalletBalance = () => {
  return (
    <CustomRow gap="8px" justifyContent="space-between">
      <CustomText
        text="Wallet Balance"
        className="labelSmall"
        color="var(--text-2)"
      />
      <CustomText
        text="0.00 USDC"
        className="labelMedium"
        color="var(--text-1)"
      />
    </CustomRow>
  );
};

export default DepositPage;
