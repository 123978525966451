import React, { createContext, useState } from "react";

export const GlobalContext = createContext({});

const GlobalContextProvider = (props) => {
  const [snackBarState, setSnackBarState] = useState({
    open: false,
    message: "",
    type: "",
    vertical: "bottom",
    horizontal: "left",
  });

  const handleErrorSnackbar = (
    err,
    customMessage = null,
    showFullError = true,
    position = { vertical: "bottom", horizontal: "left" },
    duration = 2000
  ) => {
    if (err === null || err.message !== "canceled")
      setSnackBarState({
        open: true,
        message: customMessage?.length
          ? err
            ? `${customMessage} ${showFullError ? `Error: ${err.message}` : ``}`
            : customMessage
          : err.message,
        type: "error",
        vertical: position.vertical,
        horizontal: position.horizontal,
        duration,
      });
  };

  const handleSuccessSnackbar = (
    customMessage = null,
    position = { vertical: "bottom", horizontal: "left" }
  ) => {
    if (!position.vertical) position.vertical = "bottom";
    if (!position.horizontal) position.horizontal = "left";
    setSnackBarState({
      open: true,
      message: customMessage,
      type: "success",
      vertical: position.vertical,
      horizontal: position.horizontal,
    });
  };
  return (
    <GlobalContext.Provider
      value={{
        snackBarState,
        setSnackBarState,
        handleErrorSnackbar,
        handleSuccessSnackbar,
      }}
    >
      {props.children}
    </GlobalContext.Provider>
  );
};

export default GlobalContextProvider;
