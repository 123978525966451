import React, { useContext } from "react";
import AppBar from "components/appbar";
import TeamPlayerTile from "./team_player_tile";
import {
  PaddingComponent,
  ScrollingContainer,
  ColumnScrolling,
} from "components/components";
import { SecondaryStyledButton } from "components/buttons";
import withLoader from "shared/withLoader";
import { getChallengeTokensDetails } from "api/team.api";
import SelectTeamBoosters from "./select_team_boosters";
import { GlobalContext } from "contexts/global_context";

const CreateTeamPage = ({ queryData }) => {
  const [tokensList] = React.useState(queryData);
  const [selectedPlayersList, setSelectedPlayersList] = React.useState([]);
  const [isSelectingBoosters, setIsSelectingBoosters] = React.useState(false);
  const [proBooster, setProBooster] = React.useState(null);
  const [semiBooster, setSemiBooster] = React.useState(null);
  const [miniBooster, setMiniBooster] = React.useState(null);
  const { handleErrorSnackbar } = useContext(GlobalContext);
  const playersRequiredFloor = Math.floor(tokensList.length / 2);

  const handlePlayerSelection = (tokenDetails) => {
    if (selectedPlayersList.includes(tokenDetails)) {
      setSelectedPlayersList(
        selectedPlayersList.filter((player) => player !== tokenDetails)
      );
    } else {
      setSelectedPlayersList([...selectedPlayersList, tokenDetails]);
    }
  };

  const handleBoosterSelection = (tokenDetails, type) => {
    //if same token details set for pro and mini then reset
    if (proBooster && proBooster === tokenDetails) {
      setProBooster(null);
    }
    if (miniBooster && miniBooster === tokenDetails) {
      setMiniBooster(null);
    }
    if (semiBooster && semiBooster === tokenDetails) {
      setSemiBooster(null);
    }
    if (type === "pro") {
      setProBooster(tokenDetails);
    } else if (type === "semi") {
      setSemiBooster(tokenDetails);
    } else if (type === "mini") {
      setMiniBooster(tokenDetails);
    }
  };

  if (isSelectingBoosters) {
    return (
      <SelectTeamBoosters
        selectedPlayersList={selectedPlayersList}
        setIsSelectingBoosters={setIsSelectingBoosters}
        proBooster={proBooster}
        semiBooster={semiBooster}
        miniBooster={miniBooster}
        handleBoosterSelection={handleBoosterSelection}
      />
    );
  }

  return (
    <ScrollingContainer>
      <AppBar title="Create Team" />
      <PaddingComponent height="16px" />
      <ColumnScrolling>
        {tokensList.map((tokenDetails, index) => (
          <TeamPlayerTile
            key={index}
            isBoosterTile={isSelectingBoosters}
            isSelected={selectedPlayersList.includes(tokenDetails)}
            tokenDetails={tokenDetails}
            handlePlayerSelection={handlePlayerSelection}
          />
        ))}
      </ColumnScrolling>
      <div
        style={{
          padding: "16px",
        }}
      >
        <SecondaryStyledButton
          onClick={(e) => {
            e.stopPropagation();
            if (selectedPlayersList.length < playersRequiredFloor) {
              handleErrorSnackbar(
                null,
                `Please select ${playersRequiredFloor} players at least`
              );
              return;
            }
            setIsSelectingBoosters(true);
          }}
        >
          {selectedPlayersList.length < playersRequiredFloor
            ? `Select ${selectedPlayersList.length}/${playersRequiredFloor} tokens`
            : "Create Team"}
        </SecondaryStyledButton>
      </div>
    </ScrollingContainer>
  );
};

export default withLoader(CreateTeamPage, getChallengeTokensDetails, {
  queryKey: ({ challengeId }) => ["challenges", "create-team", challengeId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
