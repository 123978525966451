import React from "react";
import { CustomText, CustomRow } from "components/components";
import { useHistory } from "react-router-dom";

const CreateTeamButton = ({ challengeId }) => {
  const history = useHistory();
  return (
    <div
      style={{
        cursor: "pointer",
        paddingRight: "12px",
      }}
      onClick={(e) => {
        e.stopPropagation();
        history.push(`/create_team/${challengeId}`);
      }}
    >
      <CustomRow>
        <CustomText
          text="+ Create Team"
          className="bodyMedium"
          fontWeight="bold"
          color="var(--primary-color)"
        />
      </CustomRow>
    </div>
  );
};

export default CreateTeamButton;
