import { styled } from "@mui/material/styles";
import { TextField } from "@mui/material";

const CustomTextField = styled(TextField)(({ width, height }) => ({
  width: width,
  "& .MuiInputBase-root": {
    height: height,
  },
  "& label": {
    paddingLeft: "6px",
  },
  "& .MuiInputLabel-root": {
    color: "var(--text-2)",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "150%",
    letterSpacing: "0.01em",
    "&.Mui-focused": {
      color: "var(--text-3)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "130%",
    },
    "&.Mui-error": {
      color: "var(--error)",
      fontFamily: "Roboto",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "18px",
      lineHeight: "130%",
    },
  },
  "& input": {
    backgroundColor: "transparent",
    color: "var(--text-2)",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "18px",
    lineHeight: "150%",
    paddingLeft: "21px",
  },
  backgroundColor: "var(--base)",
  borderRadius: "8px",
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "var(--border-light)",
      borderSize: "1px",
      paddingLeft: "14px",
    },
    "&:hover fieldset": {
      borderColor: "var(--border-light)",
      borderSize: "1px",
    },
    "&.Mui-focused fieldset": {
      borderColor: "var(--border-light)",
      borderSize: "1px",
    },
    "&.Mui-error fieldset": {
      borderColor: "var(--border-light)",
      borderSize: "1px",
    },
    "& MuiInputBase-input": {
      fontWeight: "500",
    },
  },
}));

export default CustomTextField;
