export function convertToLocalTime(isoString) {
  const date = new Date(isoString);
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}

export function getTimeDifferenceString(isoString) {
  const currentDate = new Date();
  const targetDate = new Date(isoString);
  const differenceInMilliseconds = targetDate - currentDate;

  // Calculate difference in total seconds, minutes, and hours
  const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);
  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  const differenceInHours = Math.floor(differenceInMinutes / 60);

  // Remaining minutes and seconds after extracting hours
  const remainingMinutes = differenceInMinutes % 60;
  const remainingSeconds = differenceInSeconds % 60;

  // Check if the difference is less than 24 hours
  if (differenceInHours < 24) {
    if (differenceInHours > 0) {
      // Return the time difference in hours and minutes
      return {
        is_less_than_24_hour: true,
        time: `${differenceInHours}h ${remainingMinutes}m`,
      };
    } else if (differenceInMinutes > 0) {
      // Return the time difference in minutes and seconds
      return {
        is_less_than_24_hour: true,
        time: `${remainingMinutes}m ${remainingSeconds}s`,
      };
    } else {
      // Return the time difference in seconds only
      return {
        is_less_than_24_hour: true,
        time: `${remainingSeconds}s`,
      };
    }
  }

  // Check if the difference is between 24 to 48 hours
  if (differenceInHours >= 24 && differenceInHours < 48) {
    if (targetDate.getDate() === currentDate.getDate() + 1) {
      return {
        is_less_than_24_hour: false,
        time: "Tomorrow",
      };
    }
  }

  // Return the date in "dd MMM" format for difference more than 48 hours
  const options = { day: "2-digit", month: "short" }; // Format options for the toLocaleDateString method
  const dateFormatted = targetDate.toLocaleDateString(undefined, options);

  return {
    is_less_than_24_hour: false,
    time: dateFormatted,
  };
}

export const isBeforeCurrentTime = (dateISO) => {
  const providedDate = new Date(dateISO);
  const currentDate = new Date();

  // Check if the provided date is valid
  if (isNaN(providedDate.getTime())) {
    throw new Error("Invalid date format");
  }

  return providedDate < currentDate;
};
