import AppBar from "components/appbar";
import {
  PaddingComponent,
  ScrollingContainer,
  ColumnScrolling,
  Divider,
  InfoBox,
  CustomColumn,
  CustomRow,
  CustomText,
  FlexGrow,
} from "components/components";
import { QRCodeSVG } from "qrcode.react";
import { Box } from "@mui/material";
import { getTrimedAddress } from "utils/misc";
import { useWallets } from "@privy-io/react-auth";
import Copyable from "components/copyable";
import { PrimaryStyledButton } from "components/buttons";
import FundWalletPopUp from "modules/privy/fund_wallet";
import React from "react";

const DepositPage = () => {
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <ScrollingContainer>
      <ColumnScrolling>
        <Box>
          <AppBar title="Deposit" />
          <CustomColumn padding="16px" gap="16px">
            <DepositInfoContainer address={embeddedWallet?.address} />
            <TalkToUs />
            <PaddingComponent height="16px" />
            <div>
              <QRCodeSVG
                value={embeddedWallet?.address}
                size={200}
                fgColor={"var(--base)"}
                bgColor="var(--text-1)"
              />
              <PaddingComponent height="12px" />
              <CustomText
                text="Scan QR code to deposit"
                className="labelSmall"
                color="var(--text-2)"
              />
            </div>
            <CustomRow justifyContent="center">
              <PrimaryStyledButton
                width="160px"
                isLoginRequired={true}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(true);
                }}
              >
                Fund Wallet
              </PrimaryStyledButton>
            </CustomRow>
          </CustomColumn>
        </Box>
      </ColumnScrolling>
      {isOpen && (
        <FundWalletPopUp
          open={isOpen}
          handleClose={() => {
            setIsOpen(false);
          }}
        />
      )}
    </ScrollingContainer>
  );
};

const TalkToUs = () => {
  return (
    <div
      onClick={() => {
        window.open("https://t.me/krraigs", "_blank");
      }}
      style={{
        cursor: "pointer",
        padding: "8px",
      }}
    >
      <CustomText
        text="Have doubts? Talk to us"
        className="labelSmall"
        color="var(--text-2)"
      />
    </div>
  );
};

const DepositInfoContainer = ({ address }) => {
  const addressShort = getTrimedAddress(address);
  return (
    <InfoBox padding="8px 0">
      <CustomColumn>
        <InfoTile
          index="1"
          text="Send USDC on Base to your embedded wallet address along with some ETH for gas fees."
          trailingWidget={
            <CustomRow gap="8px">
              <CustomText
                text={addressShort}
                className="labelSmall"
                color="var(--text-2)"
              />
              <Copyable content={address} />
            </CustomRow>
          }
        />
        <InfoTile
          index="2"
          text="Deposit will be credited to your wallet within few moments."
        />
        <InfoTile
          index="3"
          text="Start creating team with amount in your wallet to win dream pool."
          isDivider={false}
        />
      </CustomColumn>
    </InfoBox>
  );
};

const InfoTile = ({ index, text, trailingWidget, isDivider = true }) => {
  return (
    <CustomColumn gap="8px" padding="4px 0">
      <CustomRow
        padding="0 12px"
        gap="12px"
        alignItems="flex-start"
        justifyContent="flex-start"
      >
        <div
          style={{
            background: "var(--elevation-1)",
            borderRadius: "50%",
            width: "24px",
            height: "24px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            boxSizing: "border-box",
          }}
        >
          <CustomText
            text={index}
            className="labelSmall"
            color="var(--text-2)"
          />
        </div>
        <FlexGrow>
          <CustomColumn gap="8px" padding="2px 0 0 0">
            <CustomText
              text={text}
              className="labelSmall"
              textAlign="left"
              color="var(--text-1)"
            />
            {trailingWidget && trailingWidget}
          </CustomColumn>
        </FlexGrow>
      </CustomRow>
      {isDivider && <Divider />}
    </CustomColumn>
  );
};

export default DepositPage;
