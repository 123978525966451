import StickyTabs from "components/sticky_tabs";
import React, { useState } from "react";
import { Box } from "@mui/material";
import AppBar from "components/appbar";
import ContestDetailsTile from "./contest_details_tile";
import {
  PaddingComponent,
  ScrollingContainer,
  ColumnScrolling,
  Divider,
  CustomColumn,
} from "components/components";
import ContestLeaderboardPage from "./contest_leaderboard_page";
import ContestWinningsPage from "./contest_winnings_page";
import { getContestDetails } from "api/contests.api";
import withLoader from "shared/withLoader";

const ContestPage = ({ contestId, queryData, challengeId }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <CustomColumn>
      <AppBar title="Contest Details" />
      <ScrollingContainer>
        <ColumnScrolling>
          <PaddingComponent height="16px" />
          <ContestDetailsTile contest={queryData} challengeId={challengeId} />
          <PaddingComponent height="16px" />
          <Divider />
          <StickyTabs
            value={value}
            handleChange={handleChange}
            tabs={["Winnings", "Leaderboard"]}
          />
          <Box
            sx={{
              height: "100vh",
            }}
          >
            {value === 0 && <ContestWinningsPage contestId={contestId} />}
            {value === 1 && <ContestLeaderboardPage contestId={contestId} />}
          </Box>
        </ColumnScrolling>
      </ScrollingContainer>
    </CustomColumn>
  );
};

export default withLoader(ContestPage, getContestDetails, {
  queryKey: ({ contestId }) => ["contest", contestId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
