import React from "react";
import { CustomText } from "components/components";

const SelectBooster = ({
  text,
  type,
  size = "32px",
  handleBoosterSelection,
  isSelected,
  tokenDetails,
}) => {
  return (
    <div
      onClick={(e) => {
        e.stopPropagation();
        handleBoosterSelection(tokenDetails, type);
      }}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: size,
        width: size,
        borderRadius: "40px",
        backgroundColor: isSelected ? "var(--primary-color)" : "var(--base)",
        border: "1px solid var(--border-light)",
      }}
    >
      <CustomText
        text={text}
        className="labelSmall"
        color={isSelected ? "#000" : "var(--text-3)"}
      />
    </div>
  );
};

export default SelectBooster;
