import {
  CustomRow,
  CustomText,
  Divider,
  FlexGrow,
  PaddingComponent,
} from "./components";
import classes from "./popup.module.css";
import { X } from "@phosphor-icons/react";

const Popup = ({
  children,
  onClose,
  bgColor = "var(--navBg)",
  title,
  crossSize = 20,
  isDivider = false,
  titleFontSize = "24px",
  horizontalPadding = "20px",
  verticalPadding = "0px",
  width = "560px",
  titleFontWeight = "500",
  titleAlign = "flex-start",
  titleLetterSpacing = "-0.48px",
  header = null,
}) => {
  return (
    <div className={classes.popup}>
      <div
        className={classes.glass}
        onClick={(e) => {
          e.stopPropagation();
          onClose(false);
        }}
      >
        <div
          className={classes.popup_content}
          style={{
            backgroundColor: bgColor,
            width: width,
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <PaddingComponent padding={`${verticalPadding} ${horizontalPadding}`}>
            <CustomRow alignItems="center">
              <FlexGrow style={{ justifyContent: titleAlign }}>
                <CustomRow justifyContent={titleAlign}>
                  {title && (
                    <CustomText
                      text={title}
                      color="var(--text-1)"
                      className="titleSmall"
                      textAlign={titleAlign}
                    />
                  )}
                </CustomRow>
              </FlexGrow>
              <CrossIcon onClose={onClose} size={crossSize} />
            </CustomRow>
          </PaddingComponent>
          {isDivider && <Divider borderColor="var(--border-light)" />}
          <PaddingComponent padding={`0px ${horizontalPadding}`}>
            {children}
          </PaddingComponent>
        </div>
      </div>
    </div>
  );
};

export const CrossIcon = ({ onClose, size }) => {
  return (
    <X
      size={size}
      color="var(--text-1)"
      onClick={(e) => {
        e.stopPropagation();
        onClose(false);
      }}
      style={{
        marginBottom: "2px",
        cursor: "pointer",
      }}
    />
  );
};

export default Popup;
