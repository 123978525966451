export const abbreviateNumber = (number, format = false) => {
  if (number == null) {
    return number;
  }

  const absNumber = Math.abs(Number(number));

  if (absNumber >= 1000000000000) {
    return Number.parseFloat(number).toExponential(2);
  }

  if (absNumber >= 1000) {
    const lookup = [
      { value: 1e9, symbol: "B" },
      { value: 1e6, symbol: "M" },
      { value: 1e3, symbol: "K" },
    ];
    const trailingZeroes = /\.0+$|(\.[0-9]*[1-9])0+$/;
    const matchedLookup = lookup.find(function (matchedLookup) {
      return absNumber >= matchedLookup.value;
    });

    let toFixed = 0;

    if (absNumber / matchedLookup?.value < 10) {
      toFixed = 2;
    } else {
      if (absNumber / matchedLookup?.value < 100) {
        toFixed = 1;
      }
    }

    const abbreviated = (number / matchedLookup.value)
      .toFixed(toFixed)
      .replace(trailingZeroes, "$1");

    if (format)
      return (
        parseFloat(abbreviated).toLocaleString("en-US") + matchedLookup.symbol
      );
    else return abbreviated + matchedLookup.symbol;
  }
  if (absNumber >= 100) {
    return fixDecimalPlaces(number, 0);
  }
  if (absNumber >= 10) {
    return fixDecimalPlaces(number, 1);
  }

  if (absNumber === 0) {
    return "0";
  }
  if (absNumber < 0.001) {
    let exponent = Number.parseFloat(number).toExponential(3);
    let exponentParts = exponent.split("e");
    let power = exponentParts[1];
    let zerosCount = Math.abs(power) - 1;
    let subscript = zerosCount
      .toString()
      .split("")
      .map((digit) => {
        const subscripts = ["₀", "₁", "₂", "₃", "₄", "₅", "₆", "₇", "₈", "₉"];
        return subscripts[digit];
      })
      .join("");

    let nonZeroPart = exponentParts[0].replace(".", "");
    return `0.0${subscript}${nonZeroPart}`;
  }
  if (absNumber < 0.01) {
    return fixDecimalPlaces(number, 5);
  }
  if (absNumber < 0.1) {
    return fixDecimalPlaces(number, 4);
  }
  return fixDecimalPlaces(number, 3);
};

export const fixDecimalPlaces = (number, places) => {
  // if number is float with decimal places more than specific places
  // only get specific decimal places
  if (!Number.isInteger(number)) {
    const decimalPlaces = number?.toString().split(".")[1]?.length || 0;
    if (decimalPlaces > places) {
      return number?.toFixed?.(places);
    }
  }
  return number;
};
