import React, { useState } from "react";
import GambitTooltip from "./gambit_tooltip";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import useCopyToClipboard from "customHooks/useCopyToClipboard";
import classes from "./copyable.module.css";

const Copyable = ({
  children,
  content,
  title = "Click to copy",
  showOnHover = false,
  copyIcon = null,
  disableHoverEffects = false,
}) => {
  const [copyStatus, copyText] = useCopyToClipboard(content);
  const [isHovering, setIsHovering] = useState(false);

  const handleMouseOver = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const disabledHoverClassName = disableHoverEffects
    ? classes.copyable__opaque
    : "";

  return (
    <div
      className={classes.copyable}
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {!showOnHover || isHovering || disableHoverEffects ? (
        <div onClick={(e) => e.stopPropagation()}>
          {!copyStatus ? (
            <div
              className={`${classes.copyable__button} ${disabledHoverClassName}`}
              onClick={copyText}
            >
              <GambitTooltip placement="top" title={title} arrow>
                {copyIcon || (
                  <ContentCopyIcon
                    className={classes["copyable__button--icon"]}
                  />
                )}
              </GambitTooltip>
            </div>
          ) : (
            <div
              className={`${classes.copyable__copied} ${disabledHoverClassName}`}
            >
              <GambitTooltip placement="top" title="Copied" arrow>
                <CheckCircleIcon
                  className={classes["copyable__copied--icon"]}
                />
              </GambitTooltip>
            </div>
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};
export default Copyable;
