import {
  CustomColumn,
  CustomRow,
  CustomText,
  FlexBox,
} from "components/components";
import Image from "components/Image";
import { getTrimedAddress } from "utils/misc";

const GlobalLeaderboardTile = ({ item, index }) => {
  return (
    <CustomRow padding="12px 16px">
      <FlexBox flex="2">
        <CustomRow justifyContent="flex-start" gap="8px" alignItems="center">
          <CustomText
            text={`#${index + 1}`}
            className="labelSmall"
            color="var(--text-3)"
          />
          <Image
            src={item?.logo_url}
            style={{
              borderRadius: "50%",
              width: "28px",
              height: "28px",
              objectFit: "cover",
            }}
          />
          <CustomColumn alignItems="flex-start">
            <CustomText text={item?.name || "Praveen"} className="bodySmall" />
            <CustomText
              text={getTrimedAddress(item?.creator)}
              className="minorText"
              color="var(--text-3)"
            />
          </CustomColumn>
        </CustomRow>
      </FlexBox>
      <FlexBox flex="1">
        <CustomRow alignItems="flex-end" justifyContent="flex-end">
          <CustomText text="$900" className="bodySmall" color="var(--text-2)" />
        </CustomRow>
      </FlexBox>
    </CustomRow>
  );
};

export default GlobalLeaderboardTile;
