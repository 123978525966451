import {
  CursorDiv,
  CustomRow,
  CustomText,
  FlexGrow,
  InfoBox,
} from "components/components";
import { CustomColumn } from "components/components";
import { useHistory } from "react-router-dom";
import ChallengeTimeComponent from "./challenge_time_component";
import { convertToLocalTime, isBeforeCurrentTime } from "./challenge_utils";

const ChallengeTile = ({ challenge }) => {
  const history = useHistory();
  const timeSubtitle = convertToLocalTime(challenge?.start_time);
  const isCompleted = isBeforeCurrentTime(challenge?.end_time);
  const isRunning = !isCompleted && isBeforeCurrentTime(challenge?.start_time);

  return (
    <CursorDiv width="100%">
      <InfoBox
        margin="8px 16px"
        background="var(--elevation-1)"
        onClick={() => {
          history.push(`/challenge_details/${challenge?.id}`);
        }}
      >
        <CustomColumn alignItems="flex-start">
          <CustomRow width="100%">
            <FlexGrow>
              <CustomColumn alignItems="flex-start">
                <CustomText
                  className="bodyLarge"
                  text={challenge?.title}
                  color="var(--text-1)"
                />
                <CustomText
                  className="labelMedium"
                  color="var(--text-2)"
                  text={challenge?.description}
                />
              </CustomColumn>
            </FlexGrow>
            <CustomColumn alignItems="flex-end">
              {isRunning ? (
                <CustomText
                  className="labelSmall"
                  text="Ends in"
                  color="var(--text-3)"
                />
              ) : (
                <ChallengeTimeComponent
                  time={challenge?.start_time}
                  isCompleted={isCompleted}
                />
              )}
              {isRunning ? (
                <ChallengeTimeComponent
                  time={challenge?.end_time}
                  isCompleted={false}
                />
              ) : (
                !isCompleted && (
                  <CustomText
                    className="labelSmall"
                    text={timeSubtitle}
                    color="var(--text-3)"
                  />
                )
              )}
            </CustomColumn>
          </CustomRow>
        </CustomColumn>
      </InfoBox>
    </CursorDiv>
  );
};

export default ChallengeTile;
