import * as React from "react";
import { useFundWallet } from "@privy-io/react-auth";
import { useWallets } from "@privy-io/react-auth";
import { base } from "viem/chains";
import { PrimaryStyledButton } from "components/buttons";
import CustomTextField from "components/custom_text_field.styled";
import { useState } from "react";
import {
  CursorDiv,
  CustomRow,
  CustomText,
  InfoBox,
} from "components/components";
import Popup from "components/popup";
import { CustomColumn, PaddingComponent } from "components/components";

const FundWalletPopUp = ({ handleClose }) => {
  const { fundWallet } = useFundWallet();
  const { wallets } = useWallets();
  const embeddedWallet = wallets.find(
    (wallet) => wallet.walletClientType === "privy"
  );
  const [amount, setAmount] = useState("");
  return (
    <Popup
      onClose={handleClose}
      titleFontSize="20px"
      title="Deposit USDC"
      isDivider
    >
      <CustomColumn alignItems="flex-start">
        <PaddingComponent height="16px" />
        <CustomText
          text="Select amount to deposit."
          className="bodySmall"
          textAlign="left"
          color="var(--text-1)"
        />
        <PaddingComponent height="16px" />
        <CustomTextField
          variant="outlined"
          type="text"
          height="50px"
          width="100%"
          label="Enter amount($)"
          className="textField"
          value={amount}
          onChange={(event) => {
            setAmount(event.target.value);
          }}
        />
        <PaddingComponent height="16px" />
        <DepositAmountChips
          onClick={(value) => {
            setAmount(value);
          }}
        />
        ' <PaddingComponent height="16px" />
        <PrimaryStyledButton
          onClick={async () => {
            handleClose();
            //check if amount is valid double value
            if (isNaN(amount) || amount <= 0) {
              alert("Please enter a valid amount");
              return;
            }
            await fundWallet(embeddedWallet.address, {
              chain: base,
              asset: "USDC",
              amount: amount,
            });
          }}
        >
          Add Funds
        </PrimaryStyledButton>
        <PaddingComponent height="16px" />
        <CustomText
          text="Note: Please make sure to deposit USDC on BASE to join contests along with some ETH for gas fees."
          className="displaySmall"
          textAlign="left"
          color="var(--text-2)"
        />
      </CustomColumn>
    </Popup>
  );
};

const DepositAmountChips = ({ onClick }) => {
  return (
    <CustomRow>
      <AmountChip amount={10} onClick={onClick} />
      <PaddingComponent width="8px" />
      <AmountChip amount={20} onClick={onClick} />
      <PaddingComponent width="8px" />
      <AmountChip amount={50} onClick={onClick} />
      <PaddingComponent width="8px" />
      <AmountChip amount={100} onClick={onClick} />
      <PaddingComponent width="8px" />
      <AmountChip amount={1000} onClick={onClick} />
    </CustomRow>
  );
};

const AmountChip = ({ amount, onClick }) => {
  return (
    <CursorDiv>
      <InfoBox
        radius="20px"
        padding="8px 12px"
        onClick={(e) => {
          e.stopPropagation();
          onClick(amount);
        }}
      >
        <CustomText text={`$${amount}`} className="labelSmall" />
      </InfoBox>
    </CursorDiv>
  );
};

export default FundWalletPopUp;
