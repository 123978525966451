import { getChallengeTokensDetails } from "api/team.api";
import { SelectedBoosterTile } from "./contest/my_teams_tile";
import withLoader from "shared/withLoader";
import {
  CustomColumn,
  CustomText,
  PaddingComponent,
} from "components/components";

const ChallengeTokenDetailsComp = ({ queryData }) => {
  return (
    <CustomColumn alignItems="flex-start" padding="12px 16px 0 16px">
      <CustomText text="Tokens" className="bodyMedium" />
      <div
        style={{
          gap: "12px",
          display: "flex",
          flexDirection: "row",
          overflowX: "auto",
          whiteSpace: "nowrap",
          scrollBehavior: "smooth",
          width: "100%",
          padding: "12px",
        }}
      >
        {queryData.map((token, index) => (
          <SelectedBoosterTile
            key={index}
            token={token}
            isShowingBooster={false}
          />
        ))}
        <PaddingComponent width="16px" />
      </div>
    </CustomColumn>
  );
};

export default withLoader(
  ChallengeTokenDetailsComp,
  getChallengeTokensDetails,
  {
    queryKey: ({ challengeId }) => ["challengeTokenDetails", challengeId],
    queryOptions: () => {
      return {
        staleTime: 1000 * 60 * 5,
      };
    },
    retry: 1,
  }
);
