import AppBar from "components/appbar";
import withLoader from "shared/withLoader";
import { getChallengeTeams } from "api/team.api";
import { useState } from "react";
import {
  CustomColumn,
  PaddingComponent,
  ScrollingContainer,
  ColumnScrolling,
  CustomRow,
  CustomCheckBox,
  FlexBox,
} from "components/components";
import MyTeamsTile from "modules/challenge_details/contest/my_teams_tile";
import JoinContestWrapper from "./contest/join_contest_wrapper";
import { CreateTeamEmptyState } from "./contest/my_teams_page";
import CreateTeamButton from "./create_team_button";

const SelectContestJoinTeams = ({ queryData, contestId, challengeId }) => {
  const teams = queryData?.teams || [];
  const [selectedTeams, setSelectedTeams] = useState([]);

  const handleChange = (isActive, team) => {
    if (isActive) {
      setSelectedTeams([...selectedTeams, team]);
    } else {
      setSelectedTeams(selectedTeams.filter((t) => t !== team));
    }
  };

  return (
    <ScrollingContainer>
      <AppBar title="Select Teams">
        <CreateTeamButton challengeId={challengeId} />
      </AppBar>
      <PaddingComponent height="16px" />
      <ColumnScrolling>
        <CustomColumn>
          {teams.length === 0 && (
            <CreateTeamEmptyState challengeId={challengeId} />
          )}
          {teams.map((team, index) => (
            <CustomRow alignItems="center" padding="0 16px" key={index}>
              <FlexBox>
                <MyTeamsTile
                  key={index}
                  index={index}
                  team={team}
                  showPoints={false}
                />
              </FlexBox>
              <CustomCheckBox
                isActive={selectedTeams.includes(team)}
                setIsActive={(e) => {
                  handleChange(e, team);
                }}
              />
            </CustomRow>
          ))}
          <PaddingComponent height="120px" />
        </CustomColumn>
      </ColumnScrolling>
      {teams.length !== 0 && (
        <div
          style={{
            padding: "16px",
          }}
        >
          <JoinContestWrapper
            contestId={contestId}
            challengeId={challengeId}
            selectedTeams={selectedTeams}
          />
        </div>
      )}
    </ScrollingContainer>
  );
};

export default withLoader(SelectContestJoinTeams, getChallengeTeams, {
  queryKey: ({ challengeId }) => ["challenges", "my_teams", challengeId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
