import { MyChallengesType } from "helpers/custom_enums";

export function filterChallenges(challenges, myChallengeType) {
  const now = new Date();

  switch (myChallengeType) {
    case MyChallengesType.LIVE:
      return challenges.filter(
        (challenge) =>
          new Date(challenge.start_time) <= now &&
          new Date(challenge.end_time) >= now
      );

    case MyChallengesType.COMPLETED:
      return challenges.filter(
        (challenge) => new Date(challenge.end_time) < now
      );

    case MyChallengesType.UPCOMING:
      return challenges.filter(
        (challenge) => new Date(challenge.start_time) > now
      );

    default:
      return [];
  }
}
