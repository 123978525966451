import { signedRequest } from "./api";

export const getContestDetails = async ({ contestId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/contests/${contestId}`,
  });
  return response.data;
};

export const getChallengeContests = async ({ challengeId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/challenges/${challengeId}/contests`,
  });
  return response.data;
};

export const getMyContestsForChallenge = async ({ challengeId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/challenges/${challengeId}/my_contests`,
  });
  return response.data;
};

export const joinContest = async ({ contestId, teamIds, txnId }) => {
  // check if valid txnId
  if (!txnId) {
    throw new Error("Invalid transaction ID");
  }
  const response = await signedRequest({
    method: "post",
    path: `/api/gambit/contests/${contestId}/join`,
    bodyText: JSON.stringify({
      contest_id: contestId,
      team_ids: teamIds,
      tx_id: txnId,
    }),
  });
  return response;
};

export const getWinnings = async ({ contestId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/contests/${contestId}/winnings`,
  });
  return response;
};

export const getLeaderboard = async ({ contestId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/contests/${contestId}/get_leaderboard`,
  });
  return response;
};

export const getGlobalLeaderboard = async () => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/contests/4/get_leaderboard`,
  });
  return response;
};
