import MyTeamsTile from "modules/challenge_details/contest/my_teams_tile";
import {
  CustomColumn,
  PaddingComponent,
  ColumnScrolling,
} from "components/components";
import { getChallengeTeams } from "api/team.api";
import withLoader from "shared/withLoader";
import EmptyState from "components/empty_state";
import { PrimaryStyledButton } from "components/buttons";
import { useHistory } from "react-router-dom";

const MyTeamsPage = ({ queryData, challengeId }) => {
  const teams = queryData?.teams || [];
  if (teams.length === 0) {
    return <CreateTeamEmptyState challengeId={challengeId} />;
  }
  return (
    <ColumnScrolling>
      <CustomColumn>
        {teams.map((team, index) => (
          <MyTeamsTile
            key={index}
            index={index}
            team={team}
            showPoints={true}
          />
        ))}
        <PaddingComponent height="120px" />
      </CustomColumn>
    </ColumnScrolling>
  );
};

export const CreateTeamEmptyState = ({ challengeId }) => {
  const history = useHistory();

  return (
    <EmptyState
      title="You have not created any teams yet."
      subtitle="Create teams to join contest."
    >
      <PrimaryStyledButton
        width="200px"
        onClick={(e) => {
          e.stopPropagation();
          history.push(`/create_team/${challengeId}`);
        }}
      >
        Create Team
      </PrimaryStyledButton>
    </EmptyState>
  );
};

export default withLoader(MyTeamsPage, getChallengeTeams, {
  queryKey: ({ challengeId }) => ["challenges", "my_teams", challengeId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
