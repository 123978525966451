import { forwardRef } from "react";
import tokenPlaceholder from "assets/token_placeholder.png";

const Image = forwardRef(
  (
    {
      src,
      alt,
      className,
      fallbackClassName,
      style,
      onLoad = () => {},
      onErrorCallback = () => {},
      ...props
    },
    ref
  ) => {
    return (
      <img
        ref={ref}
        src={src ?? tokenPlaceholder}
        alt={alt}
        {...props}
        style={style}
        className={className}
        onLoad={onLoad}
      />
    );
    //   uncomment the below line for testing
    // return <img src={`${source}break`} alt={alt} {...props} onError={onError} />;
  }
);

export default Image;
