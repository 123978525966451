import React from "react";
import {
  CustomColumn,
  CustomRow,
  CustomText,
  FlexGrow,
  PaddingComponent,
} from "components/components";
import Image from "components/Image";
import { abbreviateNumber } from "utils/number_utils";
import { ArrowUpRight } from "@phosphor-icons/react";

const TeamTokenDetailsTile = ({ tokenDetails }) => {
  const formattedMCap = abbreviateNumber(tokenDetails?.market_cap, true);
  const formattedPrice = abbreviateNumber(tokenDetails?.price, true);
  const subtitle = `M.Cap: $${formattedMCap} Price: $${formattedPrice}`;

  return (
    <div
      style={{
        cursor: "pointer",
        borderBottom: "1px solid var(--border-light)",
        padding: "12px 16px",
        backgroundColor: "var(--base)",
      }}
      onClick={() => {
        if (tokenDetails?.page_url) {
          window.open(tokenDetails?.page_url, "_blank");
        }
      }}
    >
      <CustomRow alignItems="center">
        <Image
          src={tokenDetails?.logo_url}
          style={{
            borderRadius: "50%",
            width: "48px",
            height: "48px",
            objectFit: "cover",
          }}
        />
        <PaddingComponent width="8px" />
        <FlexGrow>
          <CustomColumn
            alignItems="flex-start"
            justifyContent="center"
            height="48px"
          >
            <CustomRow gap="4px" alignItems="center">
              <CustomText text={tokenDetails?.name} className="bodyMedium" />
              {tokenDetails?.points > 1 && (
                <CustomText
                  text={`${tokenDetails?.points}x`}
                  className="displaySmall"
                  color="var(--text-2)"
                />
              )}
            </CustomRow>
            <CustomText
              text={subtitle}
              className="labelSmall"
              color="var(--text-3)"
            />
          </CustomColumn>
        </FlexGrow>
        <CustomText
          text={tokenDetails?.score?.toFixed(2)}
          className="bodyMedium"
        />
        <PaddingComponent width="8px" />
        <ArrowUpRight size={18} color="var(--text-2)" />
      </CustomRow>
    </div>
  );
};

export default TeamTokenDetailsTile;
