import ContestTile from "modules/challenge_details/contest/contest_tile";
import {
  CustomColumn,
  PaddingComponent,
  ColumnScrolling,
} from "components/components";
import { getChallengeContests } from "api/contests.api";
import withLoader from "shared/withLoader";

const ChallengeContestsPage = ({ queryData, challengeData }) => {
  const contestsList = queryData || [];
  return (
    <ColumnScrolling>
      <CustomColumn>
        {contestsList.map((contest, index) => (
          <ContestTile
            key={index}
            contest={contest}
            challengeData={challengeData}
          />
        ))}
        <PaddingComponent height="120px" />
      </CustomColumn>
    </ColumnScrolling>
  );
};

export default withLoader(ChallengeContestsPage, getChallengeContests, {
  queryKey: ({ challengeId }) => ["challenges", challengeId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 2,
});
