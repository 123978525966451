import React, { useState } from "react";
import {
  CustomColumn,
  ScrollingContainer,
  ColumnScrolling,
} from "components/components";
import AppBar from "components/appbar";
import CreateTeamButton from "./create_team_button";
import { Box } from "@mui/material";
import StickyTabs from "components/sticky_tabs";
import MyContestsPage from "./contest/my_contests_page";
import MyTeamsPage from "./contest/my_teams_page";
import ChallengeContestsPage from "./contest/challenge_contests_page";
import { useParams } from "react-router-dom";
import withLoader from "shared/withLoader";
import { getChallengeDetails } from "api/challenges.api";
import ChallengeTokenDetailsComp from "./challenge_token_details";

const ChallengeDetailsPage = ({ queryData }) => {
  const [value, setValue] = useState(0);
  const { challengeId } = useParams();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <CustomColumn>
      <AppBar title={queryData.title}>
        <CreateTeamButton challengeId={challengeId} />
      </AppBar>
      <ScrollingContainer>
        <ColumnScrolling>
          <ChallengeTokenDetailsComp challengeId={challengeId} />
          <StickyTabs
            value={value}
            handleChange={handleChange}
            tabs={["Contests", "My Contests", "Teams"]}
          />
          <Box
            sx={{
              height: "100vh",
            }}
          >
            {value === 0 && (
              <ChallengeContestsPage
                challengeId={challengeId}
                challengeData={queryData}
              />
            )}
            {value === 1 && <MyContestsPage challengeId={challengeId} />}
            {value === 2 && <MyTeamsPage challengeId={challengeId} />}
          </Box>
        </ColumnScrolling>
      </ScrollingContainer>
    </CustomColumn>
  );
};

export default withLoader(ChallengeDetailsPage, getChallengeDetails, {
  queryKey: ({ challengeId }) => ["challengeDetails", challengeId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
