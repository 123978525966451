import ContestTile from "modules/challenge_details/contest/contest_tile";
import {
  CustomColumn,
  PaddingComponent,
  ColumnScrolling,
} from "components/components";
import withLoader from "shared/withLoader";
import { getMyContestsForChallenge } from "api/contests.api";
import EmptyState from "components/empty_state";
import { PrimaryStyledButton } from "components/buttons";
import { useHistory } from "react-router-dom";

const MyContestsPage = ({ queryData, challengeId }) => {
  const contestsList = queryData || [];
  const history = useHistory();
  if (contestsList.length === 0) {
    return (
      <EmptyState
        title="You have not joined any contest yet."
        subtitle="Create a team and join a contest to win big"
      >
        <PrimaryStyledButton
          width="200px"
          onClick={(e) => {
            e.stopPropagation();
            history.push(`/create_team/${challengeId}`);
          }}
        >
          Create Team
        </PrimaryStyledButton>
      </EmptyState>
    );
  }
  return (
    <ColumnScrolling>
      <CustomColumn>
        {contestsList.map((contest, index) => (
          <ContestTile key={index} contest={contest} />
        ))}
        <PaddingComponent height="120px" />
      </CustomColumn>
    </ColumnScrolling>
  );
};

export default withLoader(MyContestsPage, getMyContestsForChallenge, {
  queryKey: ({ challengeId }) => ["challenges", "my_contests", challengeId],
  queryOptions: () => {
    return {
      staleTime: 1000 * 60 * 5,
    };
  },
  retry: 1,
});
