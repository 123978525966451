import {
  CustomColumn,
  CustomText,
  FlexGrow,
  PaddingComponent,
} from "components/components";
import classes from "./login.module.css";
import ConnectWalletButton from "./components/connect_wallet_button";

const LoginPage = () => {
  return (
    <CustomColumn padding="16px" alignItems="center" height="100%">
      <PaddingComponent height="36px" />
      <CustomColumn alignItems="center">
        <img
          src={require("assets/logo.png")}
          alt="logo"
          className={classes.img}
        />
        <PaddingComponent height="16px" />
        <CustomText text="Log In to Gambit" className="titleSmall" />
      </CustomColumn>
      <PaddingComponent height="32px" />
      <ConnectWalletButton />
      <PaddingComponent height="18px" />
      <FlexGrow />
    </CustomColumn>
  );
};

export default LoginPage;
