import React, { useState, useEffect } from "react";
import { CustomText } from "components/components";
import { getTimeDifferenceString } from "./challenge_utils";

const Stopwatch = ({ time, isCompleted }) => {
  const [timeLeft, setTimeLeft] = useState(getTimeDifferenceString(time));

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(getTimeDifferenceString(time));
    }, 1000); // Update every second

    return () => clearInterval(timer);
  }, [time]);

  return (
    <CustomText
      className="labelSmall"
      text={isCompleted ? "Completed" : timeLeft.time}
      color={timeLeft.is_less_than_24_hour ? "var(--error)" : "var(--text-2)"}
    />
  );
};

export default Stopwatch;
