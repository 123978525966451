import axios from "axios";
import { v4 as uuidv4 } from "uuid";
import { getAccessToken } from "@privy-io/react-auth";

export const backendURL = "https://gambit.bharathsingh.com";

export const backend = axios.create({
  baseURL: backendURL,
});

function isEncoded(uri) {
  uri = uri || "";

  return uri !== decodeURI(uri);
}

export const signedRequest = async ({
  method,
  path,
  headers,
  bodyText,
  signal,
}) => {
  // if the user of the function didn't add a slash in the beginning of the path
  const firstChar = path.slice(0, 1);
  if (firstChar !== "/") {
    path = `/${path}`;
  }

  const requestId = uuidv4().toString().replace(/-/g, "");

  if (!isEncoded(path)) {
    path = encodeURI(path);
  }

  headers = headers || {};
  headers["X-Request-ID"] = requestId; // Add request_id as a header
  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json"; // Set Content-Type to application/json if not already set
  }

  let authHeaders;

  authHeaders = await calculateAuthHeaders(method, path, bodyText || "");

  const response = await backend.request({
    url: path,
    method,
    headers: {
      ...(headers || {}),
      ...authHeaders,
    },
    data: bodyText,
    signal,
  });
  return response;
};

export const signedApiRequest = async (config) => {
  config.path = `/api/gambit/${config.path || ""}`;
  return signedRequest(config);
};

export const calculateAuthHeaders = async () => {
  const accessToken = await getAccessToken();

  return {
    Authorization: `Bearer ${accessToken}`,
  };
};
