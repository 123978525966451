import { signedRequest } from "./api";

export const getChallengeTokensDetails = async ({ challengeId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/challenges/${challengeId}/team-details`,
  });
  return response.data;
};

export const createTeam = async ({
  challengeId,
  normalTokens,
  proBoosterToken,
  semiBoosterToken,
  miniBoosterToken,
}) => {
  const tokens = normalTokens.map((player) => {
    return {
      coingecko_slug: player.coingecko_slug,
      chain_id: player.chain_id,
      token_address: player.token_address,
      points: 1,
    };
  });
  tokens.unshift({
    coingecko_slug: miniBoosterToken.coingecko_slug,
    chain_id: miniBoosterToken.chain_id,
    token_address: miniBoosterToken.token_address,
    points: 1.5,
  });
  tokens.unshift({
    coingecko_slug: semiBoosterToken.coingecko_slug,
    chain_id: semiBoosterToken.chain_id,
    token_address: semiBoosterToken.token_address,
    points: 2,
  });
  tokens.unshift({
    coingecko_slug: proBoosterToken.coingecko_slug,
    chain_id: proBoosterToken.chain_id,
    token_address: proBoosterToken.token_address,
    points: 3,
  });

  const response = await signedRequest({
    method: "post",
    path: `/api/gambit/challenges/${challengeId}/create_team`,
    bodyText: JSON.stringify(tokens),
  });
  return response;
};

export const getChallengeTeams = async ({ challengeId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/challenges/${challengeId}/my_teams`,
  });
  return response.data;
};

export const getUserTeamDetails = async ({ teamId }) => {
  const response = await signedRequest({
    method: "get",
    path: `/api/gambit/teams/${teamId}/team_details`,
  });
  return response.data;
};
