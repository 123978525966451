import React from "react";
import { BottomNavigation, BottomNavigationAction } from "@mui/material";

import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components";
import { House, Gear, Trophy } from "@phosphor-icons/react";

const CenteredContainer = styled.div`
  display: flex;
  justify-content: center; // Center horizontally
  align-items: center; // Center vertically if needed
  width: 100%;
  max-width: 1000px; // Define a max width
  margin: 0 auto; // Center the content horizontally
  padding: 0 16px; // Optional padding for better spacing
  box-sizing: border-box; // Ensures padding and border are included in the total width
`;

const CustomBottomNavigation = styled(BottomNavigation)`
  width: 100%;
  max-width: 800px;
  box-sizing: border-box;

  .MuiBottomNavigationAction-root {
    color: white;
    &.Mui-selected {
      color: var(--primary-color);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 16px); // Adding horizontal padding
        height: 4px; // Indicator height
        background-color: var(--primary-color); // Indicator color
        border-bottom-left-radius: 12px; // Bottom left border radius (for a rounded bottom look)
        border-bottom-right-radius: 12px; // Bottom right border radius
      }
    }
  }
`;
const CustomBottomNavigationAction = styled(BottomNavigationAction)`
  position: relative; // Required for the pseudo-element to position correctly
`;

const BottomNav = () => {
  const history = useHistory();
  const location = useLocation();

  const handleChange = (event, newValue) => {
    history.push(newValue);
  };

  return (
    <CenteredContainer>
      <CustomBottomNavigation
        value={location.pathname}
        onChange={handleChange}
        showLabels
        sx={{
          position: "fixed",
          bottom: 0,
          backgroundColor: "#333",
        }}
      >
        <CustomBottomNavigationAction
          label="Home"
          value="/home"
          icon={<House size={20} />}
        />
        <CustomBottomNavigationAction
          label="My Challenges"
          value="/my-challenges"
          icon={<Trophy size={20} />}
        />
        <CustomBottomNavigationAction
          label="Settings"
          value="/settings"
          icon={<Gear size={20} />}
        />
      </CustomBottomNavigation>
    </CenteredContainer>
  );
};

export default BottomNav;
